/*
 * Ebanx Messages
 *
 * This contains all the text for the Ebanx container.
 */
import { defineMessages } from "react-intl";

export const scope = 'app.payments.boleto.form';

export default defineMessages({
    'label.name': {
        id: `${scope}.label.name`,
        defaultMessage: 'Name',
    },
    'label.address': {
        id: `${scope}.label.address`,
        defaultMessage: 'Street name',
    },
    'label.street_number': {
        id: `${scope}.label.street_number`,
        defaultMessage: 'House number',
    },
    'label.city': {
        id: `${scope}.label.city`,
        defaultMessage: 'City',
    },
    'label.state': {
        id: `${scope}.label.state`,
        defaultMessage: 'State/region/province',
    },
    'label.document': {
        id: `${scope}.label.document`,
        defaultMessage: 'Document ID',
    },
    'label.zipcode': {
        id: `${scope}.label.zipcode`,
        defaultMessage: 'Postcode',
    },
    'label.phone_number': {
        id: `${scope}.label.phone_number`,
        defaultMessage: 'Phone number',
    },
    'placeholder.document': {
        id: `${scope}.placeholder.document`,
        defaultMessage: 'CPF or CNPJ',
    },
    'placeholder.state': {
        id: `${scope}.placeholder.state`,
        defaultMessage: 'Select your state',
    },
});
