import React, {useContext, useEffect, useState} from 'react';
import {IntlProvider} from "react-intl";

import {AppContext} from "../../contexts";
import {DEFAULT_LOCALE} from '../../constants/locales';

export default (props: any) => {
    const [messages, setMessages] = useState(null);

    const {locale} = useContext(AppContext);

    useEffect(() => {
        import(`../../@ueni/payment-hosted-page-intl/${locale}.json`).then(messages => {
            setMessages(messages);
        });
    }, [locale]);

	return messages !== null ? (
        <IntlProvider
            messages={messages!}
            locale={locale!}
            key={locale!}
            defaultLocale={DEFAULT_LOCALE}
            onError={() => null}
        >
            {React.Children.only(props.children)}
        </IntlProvider>
    ) : null; // TODO
}
