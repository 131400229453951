/*
 * Ebanx Messages
 *
 * This contains all the text for the Ebanx container.
 */
import { defineMessages } from "react-intl";

export const scope = 'app.ebanx.card.components.CreditCardForm';

export default defineMessages({
    'label.card_name': {
        id: `${scope}.label.card_name`,
        defaultMessage: 'Cardholder Name',
    },
    'label.card_number': {
        id: `${scope}.label.card_number`,
        defaultMessage: 'Card Number',
    },
    'label.card_due_date': {
        id: `${scope}.label.card_due_date`,
        defaultMessage: 'Card due date',
    },
    'label.card_cvv': {
        id: `${scope}.label.card_cvv`,
        defaultMessage: 'CVV',
    },
    'label.address': {
        id: `${scope}.label.address`,
        defaultMessage: 'Street name',
    },
    'label.document': {
        id: `${scope}.label.document`,
        defaultMessage: 'Document ID',
    },
    'label.street_number': {
        id: `${scope}.label.street_number`,
        defaultMessage: 'House number',
    },
    'label.city': {
        id: `${scope}.label.city`,
        defaultMessage: 'City',
    },
    'label.zipcode': {
        id: `${scope}.label.zipcode`,
        defaultMessage: 'Postcode',
    },
    'label.phone_number': {
        id: `${scope}.label.phone_number`,
        defaultMessage: 'Phone number',
    },
    'label.state': {
        id: `${scope}.label.state`,
        defaultMessage: 'State/region/province',
    },
		'label.instalments': {
			id: `${scope}.label.instalments`,
			defaultMessage: 'Instalments',
		},
    'placeholder.card_due_date': {
        id: `${scope}.placeholder.card_due_date`,
        defaultMessage: 'MM/YY',
    },
    'placeholder.document': {
        id: `${scope}.placeholder.document`,
        defaultMessage: 'Document ID',
    },
    'placeholder.state': {
        id: `${scope}.placeholder.state`,
        defaultMessage: 'Select your state',
    },
		'placeholder.instalments': {
			id: `${scope}.placeholder.instalments`,
			defaultMessage: 'Select instalments count',
		},
});
