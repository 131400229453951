import React, { useContext } from 'react';
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import messages from "./messages";
import { AppContext } from '../../contexts/AppContext/AppContext';
import { getPrivacyPageLink, getTermsPageLink } from "../../utils/externalPages";

const Wrapper = styled.div`
	margin: 1rem 0;
	font-size: 0.75rem;
	text-align: center;
`;

type Props = {
	id?: string,
	defaultMessage?: string,
	button?: React.ReactElement,
};

const TermsAndConditions: React.FC<Props> = ({button, id, defaultMessage}) => {
	const {locale} = useContext(AppContext)

	return (
		<Wrapper>
			<FormattedMessage
				id={id || messages.text.id}
				defaultMessage={defaultMessage || messages.text.defaultMessage}
				values={{
					terms: (...chunks: any[]) => <a href={getTermsPageLink(locale as string)} target="_blank" rel="noopener noreferrer">{chunks}</a>,
					privacy: (...chunks: any[]) => <a href={getPrivacyPageLink(locale as string)} target="_blank" rel="noopener noreferrer">{chunks}</a>,
					button,
				}}
			/>
		</Wrapper>
	);
}

export default TermsAndConditions;
