import React, {useContext} from 'react';
import styled from "styled-components";
import UeniLogo from "../../assets/ueni-logo.svg";
import {AppContext} from "../../contexts/AppContext/AppContext";
import PageNotFound from 'components/PageNotFound';

const Header = styled.div`
    width: 100%;
    height: 80px;
    background-color: ${({theme}) => theme.colors.headerBackground};
    background-image: url(${UeniLogo});
    background-repeat: no-repeat;
    background-position: left 50% top 30px;
`;

const Footer = styled.div`
    width: 100%;
    height: 40px;
    background-color: ${({theme}) => theme.colors.footerBackground};
`;

const Main = styled.div`
    padding: 2rem 1rem;
    max-width: 512px;
    margin: 0 auto;
    height: 100%;
    width:100%;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const AppLayout: React.FC = ({children}) => {
	const {
		embedded, country, customer_id, secret
	} = useContext(AppContext);

	return (
		<React.Fragment>
			{!embedded && <Header/>}
			<Main>
				{
					country === null ||
					customer_id === null ||
					secret === null ? (
						<PageNotFound/>
					) : children
				}
			</Main>
			{!embedded && <Footer/>}
		</React.Fragment>
	);
};

export default AppLayout;
