import React, {useContext, useEffect, useState} from "react";
import subscription from "../../services/subscription";
import {get, isEmpty} from "lodash";

import {AppContext, ReadyContext, SummaryContext} from "../../contexts";
import {ComputeResponse} from "../../contexts/types";
import {ErrorContext} from "../../contexts/ErrorContext/ErrorContext";
import {PaymentContext} from "../../contexts/PaymentContext/PaymentContext";

export const SummaryProvider = (props: any) => {

	const {
		zoho_coupon,
		customer_id,
		plan_code,
		subscription_id,
		addon_code,
	} = useContext(AppContext);

	const [, setReady] = useContext(ReadyContext);
	const [, setError] = useContext(ErrorContext);

	const [loading, setLoading] = useState(false);
	const [couponCode, setCouponCode] = useState(zoho_coupon || "");
	const [isCouponCodeValid, setIsCouponValid] = useState(false);
	const [compute, setCompute] = useState<ComputeResponse | null>(null);
	const [instalmentsCount, setInstalmentsCount] = useState<number>(1);
	const { activeProvider } = useContext(PaymentContext);

	useEffect(() => {
		if(activeProvider) {
			setLoading(true);
			subscription
				.get('/v2/price', {
					params: {
						customer_id,
						plan_code,
						subscription_id,
						addon_code,
						coupon_code: couponCode,
						provider_name: activeProvider,
					}
				})
				.then(response => {
					if (response.status && response.status === 200) {
						const compute = get(response, "data", null);
						setCompute(compute);
						setInstalmentsCount(1);
						setLoading(false);

						if (couponCode && isEmpty(compute.coupon_data)) {
							setIsCouponValid(false);
						} else {
							setIsCouponValid(true);
						}

					} else {
						setLoading(false);
						throw new Error(String(response.status));
					}
				})
				.catch(e => {
					// FIXME
					setLoading(false);
					setError(e.message);
				})
				.finally(() => {
					setReady((ready) => ({
						...ready,
						summary: true
					}))
				});
		}
	}, [couponCode, activeProvider]);

	const context = {
		loading,
		compute,
		couponCode,
		isCouponCodeValid,
		setCouponCode,
		instalmentsCount,
		setInstalmentsCount,
	};

	return (
		<SummaryContext.Provider value={context}>
			{props.children}
		</SummaryContext.Provider>
	)
};
