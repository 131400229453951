import React, {useContext} from "react";
import {Route, Switch, useRouteMatch} from "react-router";
import styled from "styled-components";

import PageNotFound from "../../components/PageNotFound";
import OrderSummary from "components/OrderSummary";
import {Payments} from "./components/Payments";

import LoaderCircle from "components/LoaderCircle";

import {AppContext, ErrorContext, ReadyContext} from "../../contexts";
import {Ebanx} from "./routes/ebanx";
import {OnePay} from "./routes/onepay";

const LoaderWrapper = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	max-height: 400px;
`;

export const Charge = () => {
	const { path } = useRouteMatch();
	const {plan_code} = useContext(AppContext);

	const [ready] = useContext(ReadyContext);
	const [error] = useContext(ErrorContext);

	return (
		<Switch>
			<Route path={`${path}`} exact>
				{
					ready.summary && ready.payments && plan_code && error === null ? (
						<>
							<OrderSummary/>
							<Payments/>
						</>
					) : (
						<LoaderWrapper>
							<LoaderCircle size={48} color="#333333"/>
						</LoaderWrapper>
					)
				}
			</Route>
			<Route path={`${path}/ebanx`} component={Ebanx}/>
			<Route path={`${path}/onepay`} component={OnePay}/>
			<Route path="*" component={PageNotFound}/>
		</Switch>
	)
};
