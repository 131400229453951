// https://www.npmjs.com/package/iso-3166-2
import { useContext, useMemo } from 'react';
import iso3166 from 'iso-3166-2';
import { AppContext } from '../contexts/AppContext/AppContext';


interface Props {
	name: string
	code: string
	sub: any
};

type IsoCountry = () => Props;

/*
 * Return raw object for a country
 */
const useIso3166:IsoCountry = () => {
    const {country} = useContext(AppContext);
    return iso3166.country(country)
};

const useIsoToSelect = () => {
	const {sub} = useIso3166();
	const {country} = useContext(AppContext);

	return useMemo(() => {
		const options = Object.values(sub)
			.map((value: any) => {
				const { regionCode } = iso3166.subdivision(country, value.name);
				return {
					value: regionCode,
					label: value.name,
				}
			});
		options
			.sort((a, b) => {
				const aLabel = a.label.toUpperCase()
				const bLabel = b.label.toUpperCase()
				if (aLabel < bLabel) {
					return -1;
				}
				if (aLabel > bLabel) {
					return 1;
				}
				return 0;
			});
		return options
	},[sub, country])
}

export {useIso3166, useIsoToSelect};
