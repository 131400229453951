import React from 'react';
import {Switch, Route, useRouteMatch} from 'react-router-dom';
import {ThemeProvider, createGlobalStyle} from "styled-components";
import nest from 'recompose/nest';

import AppProvider from './providers/AppProvider';
import LanguageProvider from './providers/LanguageProvider';

import PageNotFound from "./components/PageNotFound";

import theme from 'theme';
import styles from 'theme/styles';
import AppLayout from "./components/AppLayout";

import {PaymentProvider} from "./providers/PaymentProvider";
import {SummaryProvider} from "./providers/SummaryProvider";

import {Update} from "./routes/update";
import {Charge} from "./routes/charge";
import { ReadyProvider } from "./providers/ReadyProvider";
import { ErrorProvider } from 'providers/ErrorProvider';

const GlobalStyle = createGlobalStyle`
  ${styles}
`;

const AppRoutes = React.memo(() => {
	const {path} = useRouteMatch()!;

	return (
		<Switch>
			<Route path={`${path}/charge`} component={Charge}/>
			<Route path={`${path}/update`} component={Update}/>

			<Route path={`${path}/ebanx/card/charge`} component={Charge}/>
			<Route path={`${path}/onepay/card/charge`} component={Charge}/>
			<Route path={`${path}/oxxo/card/charge`} component={Charge}/>
			<Route path={`${path}/ebanx/card/update`} component={Update}/>

			<Route component={PageNotFound}/>
		</Switch>
	)
});

const Providers = nest(
	ThemeProvider,
	AppProvider,
	LanguageProvider,
	ReadyProvider,
	ErrorProvider,
	PaymentProvider,
	SummaryProvider,

);

export default React.memo(() => (
	<Providers theme={theme}>
		<AppLayout>
			<AppRoutes/>
		</AppLayout>
		<GlobalStyle/>
	</Providers>
));
