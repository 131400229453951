import { defineMessages } from "react-intl";

export const scope = 'app.page_not_found';

export default defineMessages({
    title: {
        id: `${scope}.title`,
        defaultMessage: 'The page you requested was not found.',
    },
});
