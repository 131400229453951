export enum CARD_TYPES {
	AMEX = "amex",
	CABAL = "cabal",
	CARNET = "carnet",
	DINERSCLUB = "diners",
	ELO = "elo",
	MASTERCARD = "mastercard",
	NARANJA = "naranja",
	VISA = "visa"
};
