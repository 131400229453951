declare global {
	interface Window {
		dataLayer: any[];
	}
}

/**
 * Guarded push to Google Tag Manager dataLayer
 * @param {*} payload object
 */
export const dataLayerPush = (payload: AnalyticsEvent) => {
	if (typeof window !== 'undefined' && Array.isArray(window.dataLayer)) {
		window.dataLayer.push(payload)
	}
}
