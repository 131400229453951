import React from 'react';
import {Route, Switch, useRouteMatch} from "react-router";

import PageNotFound from "../../components/PageNotFound";
import ThankYou from "../../components/ThankYou";
import { Ebanx } from './routes/ebanx';

export const Update = () => {
	const { path } = useRouteMatch()!;
	return (
		<Switch>
			<Route path={`${path}`} exact component={Ebanx}/>
			<Route path={`${path}/thank-you`} component={ThankYou} exact/>
			<Route path="*" component={PageNotFound}/>
		</Switch>
	)
}
