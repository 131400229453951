import React, { useMemo } from "react";
import {useParams} from 'react-router';
import {Field, Form, FormikErrors, FormikProps, withFormik} from "formik";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import styled from "styled-components";
import {isEmpty} from "lodash";

// @ts-ignore
import iso3166 from "iso-3166-2";
import { cpf, cnpj } from "cpf-cnpj-validator";

// @ts-ignore
import luhn from "luhn";

import Phone from "@vtex/phone/phone-all-countries";

import Card from "assets/card.svg";
import CardBack from "assets/card_back.svg";
import {COUNTRIES} from "constants/locales";
import Button from "components/Button";
import InstalmentSelect from "components/InstalmentSelect";
import ErrorMessage from "components/ErrorMessage";
import TermsAndConditions from 'components/TermsAndConditions';
import SelectField from "components/SelectField";
import useFormikLogOnFailEffect from "hooks/useFormikLogOnFailEffect";
import useFormikSubmitted from "hooks/useFormikSubmitted";
import {useIsoToSelect} from "hooks/useIso3166";
import { dataLayerPush } from "services/dataLayer";
import ebanx from "services/ebanx";

import FormikField from "./components/FormikField";
import {matchCardNumber} from "./validations";
import messages from "./messages";
import {CARD_TYPES} from "./constants";
import {ZIP_CODE_BR_REGEXP} from "../../constants/regexp";

const now = new Date();

const StyledFormBody = styled.div`
	margin-bottom: 1rem;
`;

const Divider = styled.div`
	border-top: 1px solid #d6d6d6;
	margin: 0.5rem 0 1rem;
`;

const HorizontalGroup = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
`;

const StyledFormikField = styled(FormikField)`
	margin-bottom: 0.5rem;
`;


const EbanxCreditCardForm = (props: CreditCardFormProps & FormikProps<CreditCardFormValues> & WrappedComponentProps) => {
	const {isSubmitting, initialValues, errors, intl, values} = props;

	useFormikLogOnFailEffect()
	const submitted = useFormikSubmitted();
	const { action } = useParams();

	const {api: errorCodeOrMessage} = errors;
	const ebanxErrorMessage = useMemo(() => {
		if (errorCodeOrMessage) {
			// @ts-ignore
			const message = messages[errorCodeOrMessage];
			return message ? intl.formatMessage(message) : errorCodeOrMessage;
		}
		return null;
	}, [errorCodeOrMessage]);

	const options = useIsoToSelect();

	const matchedCard = matchCardNumber(values.card_number);

	const cardCvvMask = matchedCard && matchedCard.type === CARD_TYPES.AMEX ? "9999" : "999";

	return (
		<React.Fragment>
			<Form>
				<StyledFormBody>
					<StyledFormikField id="card_number" svgicon={Card as any} mask="9999999999999999999" required/>
					<HorizontalGroup>
						<StyledFormikField id="card_due_date" width="55%" withPlaceholder mask="99/99" required/>
						<StyledFormikField id="card_cvv" width="50%" svgicon={CardBack as any} mask={cardCvvMask} required/>
					</HorizontalGroup>
					<StyledFormikField id="card_name" maxLength={50} required/>
					<InstalmentSelect />
					<Divider/>
					{
						![COUNTRIES.MX as string, COUNTRIES.PE as string].includes(initialValues.country) && (
							<StyledFormikField id="document" maxLength={32} withPlaceholder required/>
						)
					}
					<StyledFormikField id="address" maxLength={100} required/>
					<StyledFormikField id="street_number" maxLength={30} required/>
					<StyledFormikField id="city" maxLength={80} required/>
					{
						initialValues.country === COUNTRIES.BR ? (
							<Field name="state" component={SelectField} options={options} required/>
						) : (
							<StyledFormikField id="state" maxLength={80} required/>
						)
					}
					<StyledFormikField id="zipcode" required/>
					<StyledFormikField id="phone_number" required/>

				</StyledFormBody>
				{ebanxErrorMessage && <ErrorMessage>{ebanxErrorMessage}</ErrorMessage>}
				<Button
					type="submit"
					loading={isSubmitting}
					fullwidth
					disabled={(submitted && !isEmpty(errors)) || isSubmitting}
					analyticsEvent={{event: 'checkoutEvents', category: 'Checkout', action: 'Pay', label: 'creditcard'}}
				>
					{!isSubmitting && <FormattedMessage {...(action === "charge" ? messages.submit : messages.confirm)}/>}
				</Button>
				<TermsAndConditions button={<FormattedMessage {...(action === "charge" ? messages.submit : messages.confirm)}/>}/>
			</Form>
		</React.Fragment>
	);
};

export default injectIntl(
	withFormik<CreditCardFormProps & WrappedComponentProps, any>({
		mapPropsToValues: (props: any) => {
			const {initialValues} = props;
			return {
				country: initialValues.country,
				card_name: initialValues.card_name || "",
				card_number: initialValues.card_number || "",
				card_due_date: initialValues.card_due_date || "",
				card_cvv: initialValues.card_cvv || "",
				document: initialValues.document || "",
				address: initialValues.address || "",
				street_number: initialValues.street_number || "",
				city: initialValues.city || "",
				state: initialValues.state || "",
				zipcode: initialValues.zipcode || "",
				phone_number: initialValues.phone_number || "",
			};
		},

		validateOnBlur: false,
		validateOnChange: true,
		validate: (values: CreditCardFormValues, props) => {
			const {initialValues, intl} = props;
			const errors: FormikErrors<CreditCardFormValues> = {};

			// Card number validation // Luhn algorithm
			if (!!values.card_number) {
				const isValidCardNumber = luhn.validate(values.card_number);

				if (!isValidCardNumber) {
					errors["card_number"] = intl.formatMessage(messages.cardNumberInvalid);
				}

				// SUB-186
				// if (values.card_number && isValidCardNumber && !matchCardNumber(values.card_number)) {
				// 	errors["card_number"] = intl.formatMessage(messages.cardNotSupported);
				// }

			} else {
				errors["card_number"] = intl.formatMessage(messages.cardNumberRequired);
			}

			// Card name validation // Length between 2 and 50 digits
			if (!!values.card_name) {
				if (values.card_name.length < 2) {
					errors["card_name"] = intl.formatMessage(messages.cardNameTooShort);
				} else if (values.card_name.length > 50) {
					errors["card_name"] = intl.formatMessage(messages.cardNameTooLong);
				}
			} else {
				errors["card_name"] = intl.formatMessage(messages.cardNameRequired);
			}

			// Card due date validation // Date in the format MM/YY (e.g., 10/21)
			if (!!values.card_due_date) {
				const matched = values.card_due_date.match(/^(\d\d)\/(\d\d)$/);
				const twoDigitsYear = now.getFullYear() - 2000;

				if (matched === null) {
					errors["card_due_date"] = intl.formatMessage(messages.cardDueDateInvalid);
				} else {
					const [, month, year] = matched;

					if (Number(month) > 12) {
						errors["card_due_date"] = intl.formatMessage(messages.cardDueDateInvalid);
					} else if (Number(year) < twoDigitsYear || (Number(year) === twoDigitsYear && Number(month) < now.getMonth()+1)) {
						errors["card_due_date"] = intl.formatMessage(messages.cardDueDateExpired);
					}
				}
			} else {
				errors["card_due_date"] = intl.formatMessage(messages.cardDueDateRequired);
			}

			// Credit card security code (CVV) // The length between three and four digits
			if (!!values.card_cvv) {

				// @ts-ignore
				const matchedCard = matchCardNumber(values.card_number);

				if (matchedCard && matchedCard.type === CARD_TYPES.AMEX ? !/^\d{4}$/.test(values.card_cvv) : !/^\d{3}$/.test(values.card_cvv)) {
					errors["card_cvv"] = intl.formatMessage(messages.cvvCodeInvalid);
				}
			} else {
				errors["card_cvv"] = intl.formatMessage(messages.cvvCodeRequired);
			}

			if (!values.document && ![COUNTRIES.MX as string, COUNTRIES.PE as string].includes(initialValues.country)) {
				errors["document"] = intl.formatMessage(messages.documentIdInvalid);
			}

			if (!values.state) {
				errors["state"] = intl.formatMessage(messages.stateInvalid);
			}

			if (!values.city) {
				errors["city"] = intl.formatMessage(messages.cityInvalid);
			}

			if (!values.address) {
				errors["address"] = intl.formatMessage(messages.addressInvalid);
			}

			if (!values.street_number) {
				errors["street_number"] = intl.formatMessage(messages.streetNumberInvalid);
			}

			if (!values.state) {
				errors["state"] = intl.formatMessage(messages.stateInvalid);
			}

			if (!values.zipcode) {
				errors["zipcode"] = intl.formatMessage(messages.zipcodeInvalid);
			}

			if (!values.phone_number) {
				errors["phone_number"] = intl.formatMessage(messages.phoneNumberInvalid);
			}

			switch (initialValues.country) {
				case COUNTRIES.BR: {
					if (!!values.document && !cpf.isValid(values.document, false) && !cnpj.isValid(values.document, false)) {
						errors["document"] = intl.formatMessage(messages.documentIdInvalid);
					}

					// Zipcode //Length must be 8 digits; format may be XXXXX-XXX or XXXXXXXX
					if (!!values.zipcode) {
						if (!ZIP_CODE_BR_REGEXP.test(values.zipcode)) {
							errors["zipcode"] = intl.formatMessage(messages.zipcodeInvalid);
						}
					}

					// State // ISO-3166-2 Alpha-2 code
					if (!!values.state) {
						const subdivision = iso3166.subdivision(initialValues.country, values.state);
						if (isEmpty(subdivision)) {
							errors["state"] = intl.formatMessage(messages.stateInvalid);
						}
					}

					/*
					Phone number

					Length must be between 8 and 13 digits. You can see below an example of complete phone number for both BR and MX:

					BR
					country code (+55)

					area code (XX)

					phone/mobile (XXXX-XXXX / XXXXX-XXXX)

					Example: +55 41 3140-8723 or 99872-1281

					* */
					if (!!values.phone_number) {
						const isValidPhoneNumber = Phone.validate(values.phone_number, "55");
						if (!isValidPhoneNumber) {
							errors["phone_number"] = intl.formatMessage(messages.phoneNumberInvalid);
						}
					}

					break;
				}

				case COUNTRIES.MX: {
					/*
				Phone number

				Length must be between 8 and 13 digits. You can see below an example of complete phone number:

				Example: +55 41 3140-8723 or 99872-1281

				MX
				country code (+52)

				area code (XX / XXX)

				phone/mobile (XXXX-XXXX / XXX-XXXX)

				Example: +52 040 577-7687

				* */
					if (!!values.phone_number) {
						const isValidPhoneNumber = Phone.validate(values.phone_number, "52");

						if (!isValidPhoneNumber) {
							errors["phone_number"] = intl.formatMessage(messages.phoneNumberInvalid);
						}
					}

					break;

				}

				case COUNTRIES.CL: {
					/*
				Taxpayer ID (RUT)

				Length must be 8 to 9 digits.

				Mask: x.xxx.xxx � y (8 d�gitos) xx.xxx.xxx � y (9 d�gitos)

				RUT REGEX: !/^[0-9]{7,8}[Kk0-9]$/

				The last digit(y) could be a �k� or a number.
				* */
					if (!!values.document) {
						if (!/^(\d{1,2}(\.?\d{3}){2})-?([\dkK])$/.test(values.document)) {
							errors["document"] = intl.formatMessage(messages.documentIdInvalid);
						}
					}

					if (!!values.phone_number) {
						const isValidPhoneNumber = Phone.validate(values.phone_number, "56");
						if (!isValidPhoneNumber) {
							errors["phone_number"] = intl.formatMessage(messages.phoneNumberInvalid);
						}
					}

					break;

				}

				case COUNTRIES.CO: {
					/*
				Taxpayer ID

				NIT (N�mero de Identificaci�n Tributaria)
					� Lenght of 9 to 10 digits.
				CC (C�dula de Ciudadan�a)
					� Lenght of 5 to 15 digits.
				REGEX: !/^\d{5,15}$/
				* */

					if (values.document) {
						if (values.document.match(/^\d{5,15}$/) === null) {
							errors["document"] = intl.formatMessage(messages.documentIdInvalid);
						}
					}

					if (!!values.phone_number) {
						const isValidPhoneNumber = Phone.validate(values.phone_number, "57");
						if (!isValidPhoneNumber) {
							errors["phone_number"] = intl.formatMessage(messages.phoneNumberInvalid);
						}
					}

					break;
				}

				case COUNTRIES.PE: {

					if (!!values.phone_number) {
						const isValidPhoneNumber = Phone.validate(values.phone_number, "51");
						if (!isValidPhoneNumber) {
							errors["phone_number"] = intl.formatMessage(messages.phoneNumberInvalid);
						}
					}

					break;
				}

				default: {
					if (!!values.state && (values.state.length > 80 || values.state.length < 2)) {
						errors["state"] = intl.formatMessage(messages.stateInvalid);
					}
				}
			}

			return errors;
		},

		handleSubmit: async (values: CreditCardFormValues, formikBag: any) => {
			formikBag.setSubmitting(true);

			const {country, card_name, card_number, card_due_date, card_cvv, ...rest} = values;
			const cardDueDate = card_due_date.split("/").join("/20");

			try {
				const res = await ebanx.post("/ws/token", {
					country,
					creditcard: {
						card_name,
						card_number,
						card_cvv,
						card_due_date: cardDueDate,
					},
					public_integration_key: process.env.REACT_APP_PUBLIC_INTEGRATION_KEY || "test_pk_Cy-W6eXvV8BG1vd_XZfbPw",
					payment_type_code: "creditcard",
				});

				if (res.data && res.data.status === "SUCCESS") {
					const {token, masked_card_number, payment_type_code} = res.data;
					const {onGetTokenSuccess} = formikBag.props;
					await onGetTokenSuccess({
						card: {
							token,
							masked_card_number,
							payment_type_code,
							expiration_date: cardDueDate,
						},
						values: {
							card_name,
							...rest,
						},
					});
				}
				if (res.data && res.data.status === "ERROR") {
					// @ts-ignore
					const errMessage = !!messages[res.data.status_code] ? res.data.status_code : res.data.status_message;
					formikBag.setErrors({api: errMessage});
					dataLayerPush({
						event: 'checkoutEvents',
						category: 'EbanxCard method',
						action: 'Card rejected',
						label: res.data.status_code,
					});
				}
			} catch (err) {
				const errData = err.response.data
				// @ts-ignore
				const errMessage = !!messages[errData.status_code] ? errData.status_code : errData.status_message;
				formikBag.setErrors({api: errMessage});
				dataLayerPush({
					event: 'checkoutEvents',
					category: 'EbanxCard method',
					action: 'Card rejected',
					label: errData.status_code,
				});
			} finally {
				formikBag.setSubmitting(false);
			}
		},
	})(EbanxCreditCardForm)
);
