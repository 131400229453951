import { defineMessages } from "react-intl";

export const scope = "app.components.SupportedCard";

export default defineMessages({
	label: {
		id: `${scope}.label`,
		defaultMessage: "Supported cards:",
	},
});
