import {defineMessages} from "react-intl";

export const scope = 'app.payments.boleto.success';

export default defineMessages({
	print: {
		id: `${scope}.print`,
		defaultMessage: 'You can print your boleto',
	},
	payOnline: {
		id: `${scope}.payOnline`,
		defaultMessage: 'You can pay online by using the barcode',
	},
	validFor: {
		id: `${scope}.validFor`,
		defaultMessage: 'The boleto is valid for 1 day',
	},
	onceWeReceivePayment: {
		id: `${scope}.onceWeReceivePayment`,
		defaultMessage: 'Once we receive payment, your subscription will be upgraded',
	},
	payWithBoleto: {
		id: `${scope}.payWithBoleto`,
		defaultMessage: 'Pay with Boleto',
	},
	show: {
		id: `${scope}.show`,
		defaultMessage: 'Show my Boleto',
	},
});
