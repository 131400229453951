import React, { useState, useContext, useCallback } from 'react';
import { useRouteMatch } from 'react-router';
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "components/Button";
import ErrorMessage from "components/ErrorMessage";
import TermsAndConditions from 'components/TermsAndConditions';
import { AppContext, SummaryContext } from "contexts";
import subscription from "services/subscription";

import messages from "./messages";

const INTERNATIONAL_CARD = "international_card";
const DOMESTIC_CARD = "domestic_card";

const StyledWrapper = styled.div<{
	active: boolean
}>`
	padding: 1rem 0;
	display: ${({active}) => active ? "block" : "none"}
`;

const StyledMethodRow = styled.div`
	margin-bottom: 1rem;
`;
const StyledMethodLabel = styled.label`
	cursor: pointer;
`;

const StyledMethodContainer = styled.div`
	margin-bottom: 2rem;
`

const StyledRadio = styled.input`
	&:checked,
	&:not(:checked) {
		position: absolute;
		left: -9999px;
	}
	&:checked + label,
	&:not(:checked) + label {
		position: relative;
		padding-left: 34px;
		cursor: pointer;
		line-height: 24px;
		display: inline-block;
		color: ${({ theme }) => theme.colors.text};
	}
	&:not(:checked) + label:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 20px;
		height: 20px;
		border: 2px solid ${({ theme }) => theme.colors.border};
		border-radius: 100%;
		background: #fff;
	}
	&:checked + label:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 20px;
		height: 20px;
		border: 2px solid ${({ theme }) => theme.colors.info};
		border-radius: 100%;
		background: #fff;
	}
	&:checked + label:after,
	&:not(:checked) + label:after {
		content: "";
		width: 12px;
		height: 12px;
		background: ${({ theme }) => theme.colors.info};
		position: absolute;
		top: 6px;
		left: 6px;
		border-radius: 100%;
		-webkit-transition: all 0.2s ease;
		transition: all 0.2s ease;
	}
	&:not(:checked) + label:after {
		opacity: 0;
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	&:checked + label:after {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
`;

export const OnePay = (props: {
	active: boolean
}) => {
	const { url } = useRouteMatch()!;
	const [apiError, setApiError] = useState(null);
	const [submitting, setSubmitting] = useState(false);
	const [method, setMethod] = useState(INTERNATIONAL_CARD);

	const { customer_id, redirect_url, plan_code } = useContext(AppContext);
	const { couponCode: coupon_code } = useContext(SummaryContext);
	const intl = useIntl();

	const onMethodSelect = useCallback(
		e => {
			setMethod(e.target.value)
		},
		[]
	);

	const onPayClicked = useCallback(async () => {
		const final_url = `${window.top.location.origin}${url}/success?redirect_url=${redirect_url}`;
		setApiError(null);
		setSubmitting(true);
		try {
			const res = await subscription.post(`/v1/onepay/payment_request`,
				{
					customer_id,
					plan_code,
					coupon_code,
					return_url: final_url,
					payment_method: method,
				})
				window.top.location.href = res.data.url
		} catch (err) {
			const errData = err.response.data
			// @ts-ignore
			const errMessage = !!messages[errData.status_code] ? errData.status_code : errData.status_message;
			// @ts-ignore
			const message = messages[errMessage];
			setApiError(message ? intl.formatMessage(message) : message);
			setSubmitting(false);
		}
	}, [redirect_url, url, method, customer_id, plan_code, coupon_code])

	const analyticsEvent ={
		event: 'checkoutEvents',
		category: 'Checkout',
		action: 'Pay',
		label: `onepay_${method}`
	}

	return (
		<StyledWrapper active={props.active}>
			<StyledMethodContainer>
				<StyledMethodRow>
					<StyledRadio
						type="radio"
						name="method"
						id={INTERNATIONAL_CARD}
						value={INTERNATIONAL_CARD}
						onChange={onMethodSelect}
						defaultChecked
					/>
					<StyledMethodLabel htmlFor={INTERNATIONAL_CARD}>
						<FormattedMessage {...messages.internationalCard}/>
					</StyledMethodLabel>
				</StyledMethodRow>

				<StyledMethodRow>
					<StyledRadio type="radio" name="method" id={DOMESTIC_CARD} value={DOMESTIC_CARD} onChange={onMethodSelect} />
					<StyledMethodLabel htmlFor={DOMESTIC_CARD}>
						<FormattedMessage {...messages.domesticCard} />
					</StyledMethodLabel>
				</StyledMethodRow>
			</StyledMethodContainer>

			<React.Fragment>
				{apiError && <ErrorMessage>{apiError}</ErrorMessage>}
				<Button loading={submitting} onClick={onPayClicked} analyticsEvent={analyticsEvent} fullwidth>
					<FormattedMessage {...messages.pay} />
				</Button>
				<TermsAndConditions button={<FormattedMessage {...messages.pay} />}/>
			</React.Fragment>

		</StyledWrapper>
	)
};
