import {CARD_TYPES} from "./constants";


export const AmericanExpressIncludes = new RegExp(/^((34)|(37))/);

export const DinersIncludes = new RegExp(/^(36)/);

export const MastercardIncludes = new RegExp(/^(5|(2(221|222|223|224|225|226|227|228|229|23|24|25|26|27|28|29|3|4|5|6|70|71|720)))/);
export const MastercardExcludes = new RegExp(/^(514256|514586|526461|511309|514285|501059|557909|501082|589633|501060|501051|501016|589657|553839|525855|553777|553771|551792|528733|549180|528745|517562|511849|557648|546367|501070|601782|508143|501085|501074|501073|501071|501068|501066|589671|589633|588729|501089|501083|501082|501081|501080|501075|501067|501062|501061|501060|501058|501057|501056|501055|501054|501053|501051|501049|501047|501045|501043|501041|501040|501039|501038|501029|501028|501027|501026|501025|501024|501023|501021|501020|501018|501016|501015|589657|589562|501105|557039|542702|544764|550073|528824|522135|522137|562397|566694|566783|568382|569322|504363)/);

//^(50(67(1[589]|2[012456789]|3[<wbr />01234569]|4[123567]|53|7[4568]<wbr />)|9(0(0[0137]|2[0-2]|3[59]|4[<wbr />012578]|5[1235789]|6[<wbr />013456789]|7[0134789]|8[047]|<wbr />9[123489])|1(0[0456789]|46)|<wbr />220|407))|6(27780|36368|5(0(0(<wbr />3[12356789]|4[0-9]|5[01789]|6[<wbr />0-9]|7[0-6])|4(0[6-9]|1[0-9]|<wbr />2[0-9]|3[0-9]|8[5-9]|9[0-9])|<wbr />5(0[012346789]|1[0-9]|2[0-9]|<wbr />3[0-8]|5[2-9]|6[0-9]|7[0-9]|8[<wbr />0-9]|9[0-8])|72[0-7]|9(0[1-9]|<wbr />1[0-9]|2[0128]|3[89]|4[6-9]|5[<wbr />0-9]|6[0-9]|7[0-8]))|1(6(5[2-<wbr />9]|6[0-9]|7[0-9]|8[0-9]|9[0-9]<wbr />)|70[0-4])|50(0[0-9]|1[0-9]|2[<wbr />1-9]|3[0-9]|4[0-9]|5[0-7])))|<wbr />65(0(0(4[89]|5[01789]|6[<wbr />013456789]|7[0-6])|4(1[2-9]|2[<wbr />0-9]|3[01238])|5(3[1-8]|5[2-9]<wbr />|6[0-9]|7[0-6]|8[18]|9[4-8])|<wbr />720|9(5[01236789]|6[0129]|7[<wbr />02345678]))|1(6(62|7[0-4]|8[0-<wbr />9]|9[0-9])|70[0-4])|50(05|1[<wbr />189]|2[1-9]|3[0-9]|4[0-9]|5[<wbr />0345])))
export const EloIncludes = new RegExp(/^((509091)|(636368)|(636297)|(504175)|(438935)|(40117[8-9])|(45763[1-2])|(457393)|(431274)|(50990[0-2])|(5099[7-9][0-9])|(50996[4-9])|(509[1-8][0-9][0-9])|(5090(0[0-2]|0[4-9]|1[2-9]|[24589][0-9]|3[1-9]|6[0-46-9]|7[0-24-9]))|(5067(0[0-24-8]|1[0-24-9]|2[014-9]|3[0-379]|4[0-9]|5[0-3]|6[0-5]|7[0-8]))|(6504(0[5-9]|1[0-9]|2[0-9]|3[0-9]))|(6504(8[5-9]|9[0-9])|6505(0[0-9]|1[0-9]|2[0-9]|3[0-8]))|(6505(4[1-9]|5[0-9]|6[0-9]|7[0-9]|8[0-9]|9[0-8]))|(6507(0[0-9]|1[0-8]))|(65072[0-7])|(6509(0[1-9]|1[0-9]|20))|(6516(5[2-9]|6[0-9]|7[0-9]))|(6550(0[0-9]|1[0-9]))|(6550(2[1-9]|3[0-9]|4[0-9]|5[0-8])))/);

export const VisaIncludes = new RegExp(/^(4)/);
export const VisaExcludes = new RegExp(/^((451416)|(438935)|(40117[8-9])|(45763[1-2])|(457393)|(431274)|(402934))/);

export const CarnetIncludes = new RegExp(/^(286900|502275|506(199|2(0[1-6]|1[2-578]|2[289]|3[67]|4[579]|5[01345789]|6[1-79]|7[02-9]|8[0-7]|9[234679])|3(0[0-9]|1[1-479]|2[0239]|3[02-79]|4[0-49]|5[0-79]|6[014-79]|7[0-4679]|8[023467]|9[1234689])|4(0[0-8]|1[0-7]|2[0-46789]|3[0-9]|4[0-69]|5[0-79]|6[0-38]))|588772|604622|606333|627535|636(318|379)|639(388|484|559))/);
export const NaranjaIncludes = new RegExp(/^(589562)/);
export const CabalIncludes = new RegExp(/^((627170)|(589657)|(603522)|(604((20[1-9])|(2[1-9][0-9])|(3[0-9]{2})|(400))))/);


const g = (type: CARD_TYPES, include: RegExp, exclude?: RegExp) => ({
    type, include, exclude
});



const a = [
    g(CARD_TYPES.AMEX, AmericanExpressIncludes),
    g(CARD_TYPES.DINERSCLUB, DinersIncludes),
    g(CARD_TYPES.MASTERCARD, MastercardIncludes, MastercardExcludes),
    g(CARD_TYPES.ELO, EloIncludes),
    g(CARD_TYPES.VISA, VisaIncludes, VisaExcludes),
    g(CARD_TYPES.CARNET, CarnetIncludes),
    g(CARD_TYPES.NARANJA, NaranjaIncludes),
    g(CARD_TYPES.CABAL, CabalIncludes)
];

const isRepeatingNumber = (str: string) => /^(\d)(\1){10}$/.test(str);
export function validateCPF(cpf: string)
{
	let number, digits, sum, i, result, equal_digits;
	equal_digits = 1;
	if (cpf.length < 11)
		return false;
	for (i = 0; i < cpf.length - 1; i++)
		if (cpf.charAt(i) != cpf.charAt(i + 1))
		{
			equal_digits = 0;
			break;
		}
	if (!equal_digits)
	{
		number = cpf.substring(0,9);
		digits = cpf.substring(9);
		sum = 0;
		for (i = 10; i > 1; i--)
			{
				// @ts-ignore
				sum += number.charAt(10 - i) * i;
			}
		result = sum % 11 < 2 ? 0 : 11 - sum % 11;
		// @ts-ignore
		if (result != digits.charAt(0))
			return false;
		number = cpf.substring(0,10);
		sum = 0;
		for (i = 11; i > 1; i--)
			{
				// @ts-ignore
				sum += number.charAt(11 - i) * i;
			}
		result = sum % 11 < 2 ? 0 : 11 - sum % 11;
		// @ts-ignore
		if (result != digits.charAt(1))
			return false;
		return true;
	}
	else
		return false;
}
// export function validateCPF(input: string): boolean
// {
// 	const cpf = input.replace(/\D/g, '');
//
// 	if (
// 		cpf === '' ||
// 		cpf.length !== 11 ||
// 		!/^\d{11}$/.test(cpf) ||
// 		isRepeatingNumber(cpf)
// 	) {
// 		return false;
// 	}
//
// 	const digits = cpf.split('').map(x => parseInt(x));
//
// 	for (let j = 0; j < 2; j++) {
// 		let sum = 0;
//
// 		for (let i = 0; i < 9 + j; i++) {
// 			sum += digits[i] * (10 + j - i);
// 		}
//
// 		let checkDigit = 11 - (sum % 11);
//
// 		if (checkDigit === 10 || checkDigit === 11) {
// 			checkDigit = 0;
// 		}
//
// 		if (checkDigit !== digits[9 + j]) {
// 			return false;
// 		}
// 	}
//
// 	return true;
// }

export const matchCardNumber = (card: string) => {
    return a.find(d => {
    	if (card.match(d.include)){
    		if (d.exclude) {
    			if (!card.match(d.exclude)) {
						return d;
					} else {
    				return false;
					}
				} else {
					return d;
				}
			} else {
    		return false
			}
    })
};
