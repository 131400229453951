import React from "react";
import styled from "styled-components";

import {BoletoProps} from "./types";
import BoletoForm from "./components/BoletoForm";



const StyledWrapper = styled.div<{
	active: boolean
}>`
	padding: 1rem 0;
	display: ${({active}) => active ? "block": "none"}
`;

export const Boleto = (props: BoletoProps) => (
	<StyledWrapper active={props.active}>
		<BoletoForm/>
	</StyledWrapper>
)
