import { defineMessages } from "react-intl";

export const scope = 'app.onepay.card.charge.Success';

export default defineMessages({
		'title': {
			id: `${scope}.title`,
			defaultMessage: 'We are processing your payment, please hold on',
		},
		'text': {
			id: `${scope}.text`,
			defaultMessage: 'Please do not press back, close or refresh this page',
		},
});
