import React, {useCallback, useContext, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Field, Form as FormikForm, Formik, FormikErrors} from "formik";
import styled from "styled-components";
import {cnpj, cpf} from "cpf-cnpj-validator";

import {BoletoFormValues} from "../../types";
import {useIsoToSelect} from "../../../../../../../../hooks/useIso3166";
import {COUNTRIES} from "../../../../../../../../constants/locales";
import SelectField from "../../../../../../../../components/SelectField";
import FormikField from "./components/FormikField";
import {isEmpty} from "lodash";
import Button from "../../../../../../../../components/Button";
import useFormikSubmitted from "../../../../../../../../hooks/useFormikSubmitted";
import useFormikLogOnFailEffect from "../../../../../../../../hooks/useFormikLogOnFailEffect";
import TermsAndConditions from "../../../../../../../../components/TermsAndConditions";
import messages from "./messages";
import boletoMessages from "./../../messages";
import subscription from "../../../../../../../../services/subscription";
import {AppContext} from "../../../../../../../../contexts";
import {Success} from "../Success";
import ErrorMessage from "../../../../../../../../components/ErrorMessage";
import iso3166 from "iso-3166-2";
import Phone from "@vtex/phone/phone-all-countries";
import {ZIP_CODE_BR_REGEXP} from "../../../../../../../../constants/regexp";

const StyledFormBody = styled.div`
	margin-bottom: 1rem;
`;

const StyledFormikField = styled(FormikField)`
	margin-bottom: 0.5rem;
`;

const Form = (props: {
	isSubmitting: boolean,
	initialValues: BoletoFormValues,
	apiError: any,
	errors: any
}) => {

	const {initialValues, isSubmitting, errors, apiError} = props;

	useFormikLogOnFailEffect();

	const options = useIsoToSelect();
	const submitted = useFormikSubmitted();

	return (
		<FormikForm>
			<StyledFormBody>
				<StyledFormikField id="name" required/>
				<StyledFormikField id="document" required/>
				<StyledFormikField id="address" required/>
				<StyledFormikField id="street_number" required/>
				<StyledFormikField id="city" required/>

				{
					initialValues.country === COUNTRIES.BR ? (
						<Field name="state" component={SelectField} options={options} required/>
					) : (
						<StyledFormikField id="state" maxLength={80} required/>
					)
				}

				<StyledFormikField id="zipcode" required/>
				<StyledFormikField id="phone_number" required/>

			</StyledFormBody>

			{apiError && <ErrorMessage>{apiError}</ErrorMessage>}

			<Button
				type="submit"
				loading={isSubmitting}
				fullwidth
				disabled={(submitted && !isEmpty(errors)) || isSubmitting}
				analyticsEvent={{event: 'checkoutEvents', category: 'Checkout', action: 'Pay', label: 'boleto'}}
			>
				{!isSubmitting && <FormattedMessage {...messages.pay}/>}
			</Button>
			<TermsAndConditions {...boletoMessages.termsAndConditions}/>
		</FormikForm>
	)
};


export const BoletoForm = () => {
	const intl = useIntl();
	const {country, customer_id} = useContext(AppContext);
	const [apiError, setApiError] = useState(null);

	const [paymentInfo, setPaymentInfo] = useState(null);

	const handleSubmit = useCallback(async (values: BoletoFormValues, actions: any) => {
		actions.setSubmitting(true);
		const {
			name,
			document,
			address,
			street_number,
			city,
			state,
			zipcode,
			phone_number
		} = values;

		// @ts-ignore
		const document_id = document.match(/\d+/g).join("");

		return subscription.put("/v1/boleto/info", {
			customer_id,
			name,
			document: document_id,
			address,
			street_number,
			city,
			state,
			zipcode,
			phone_number
		})
			.then((response) => {
				if (response.status === 200 || response.status === 201) {
					setPaymentInfo(response.data.payment_info)
				}
			})
			.catch((e) => {
				setApiError(e.message);
			})
	}, []);

	const initialValues = useMemo(() => ({
		country: country || "",
		name: "",
		document: "",
		address: "",
		street_number: "",
		city: "",
		state: "",
		zipcode: "",
		phone_number: "",
	}), []);

	const validate = useCallback((values: BoletoFormValues) => {
		const errors: FormikErrors<BoletoFormValues> = {};

		if (!values.name) {
			errors["name"] = intl.formatMessage(messages.nameInvalid);
		}

		if (!values.document) {
			errors["document"] = intl.formatMessage(messages.documentInvalid);
		}

		if (!values.address) {
			errors["address"] = intl.formatMessage(messages.addressInvalid);
		}

		if (!values.street_number) {
			errors["street_number"] = intl.formatMessage(messages.streetNumberInvalid);
		}

		if (!values.city) {
			errors["city"] = intl.formatMessage(messages.cityInvalid);
		}

		if (!values.state) {
			errors["state"] = intl.formatMessage(messages.stateInvalid);
		}

		if (!values.zipcode) {
			errors["zipcode"] = intl.formatMessage(messages.zipcodeInvalid);
		}

		if (!values.phone_number) {
			errors["phone_number"] = intl.formatMessage(messages.phoneNumberInvalid);
		}


		switch (initialValues.country) {
			case COUNTRIES.BR: {
				if (!cpf.isValid(values.document, false) && !cnpj.isValid(values.document, false)) {
					errors["document"] = intl.formatMessage(messages.documentInvalid);
				}

				// Zipcode //Length must be 8 digits; format may be XXXXX-XXX or XXXXXXXX
				if (!!values.zipcode) {
					if (!ZIP_CODE_BR_REGEXP.test(values.zipcode)) {
						errors["zipcode"] = intl.formatMessage(messages.zipcodeInvalid);
					}
				}

				// State // ISO-3166-2 Alpha-2 code
				if (!!values.state) {
					const subdivision = iso3166.subdivision(initialValues.country, values.state);
					if (isEmpty(subdivision)) {
						errors["state"] = intl.formatMessage(messages.stateInvalid);
					}
				}

				/*
				Phone number

				Length must be between 8 and 13 digits. You can see below an example of complete phone number for both BR and MX:

				BR
				country code (+55)

				area code (XX)

				phone/mobile (XXXX-XXXX / XXXXX-XXXX)

				Example: +55 41 3140-8723 or 99872-1281

				* */
				if (!!values.phone_number) {
					const isValidPhoneNumber = Phone.validate(values.phone_number, "55");
					if (!isValidPhoneNumber) {
						errors["phone_number"] = intl.formatMessage(messages.phoneNumberInvalid);
					}
				}

				break;
			}

			case COUNTRIES.MX: {
				/*
			Phone number

			Length must be between 8 and 13 digits. You can see below an example of complete phone number:

			Example: +55 41 3140-8723 or 99872-1281

			MX
			country code (+52)

			area code (XX / XXX)

			phone/mobile (XXXX-XXXX / XXX-XXXX)

			Example: +52 040 577-7687

			* */
				if (!!values.phone_number) {
					const isValidPhoneNumber = Phone.validate(values.phone_number, "52");

					if (!isValidPhoneNumber) {
						errors["phone_number"] = intl.formatMessage(messages.phoneNumberInvalid);
					}
				}

				break;

			}

			case COUNTRIES.CL: {
				/*
			Taxpayer ID (RUT)

			Length must be 8 to 9 digits.

			Mask: x.xxx.xxx � y (8 d�gitos) xx.xxx.xxx � y (9 d�gitos)

			RUT REGEX: !/^[0-9]{7,8}[Kk0-9]$/

			The last digit(y) could be a �k� or a number.
			* */
				if (!/^(\d{1,2}(\.?\d{3}){2})-?([\dkK])$/.test(values.document)) {
					errors["document"] = intl.formatMessage(messages.documentInvalid);
				}

				if (!!values.phone_number) {
					const isValidPhoneNumber = Phone.validate(values.phone_number, "56");
					if (!isValidPhoneNumber) {
						errors["phone_number"] = intl.formatMessage(messages.phoneNumberInvalid);
					}
				}

				break;

			}

			case COUNTRIES.CO: {
				/*
			Taxpayer ID

			NIT (N�mero de Identificaci�n Tributaria)
				� Lenght of 9 to 10 digits.
			CC (C�dula de Ciudadan�a)
				� Lenght of 5 to 15 digits.
			REGEX: !/^\d{5,15}$/
			* */

				if (values.document.match(/^\d{5,15}$/) === null) {
					errors["document"] = intl.formatMessage(messages.documentInvalid);
				}

				if (!!values.phone_number) {
					const isValidPhoneNumber = Phone.validate(values.phone_number, "57");
					if (!isValidPhoneNumber) {
						errors["phone_number"] = intl.formatMessage(messages.phoneNumberInvalid);
					}
				}

				break;
			}

			case COUNTRIES.PE: {

				if (!!values.phone_number) {
					const isValidPhoneNumber = Phone.validate(values.phone_number, "51");
					if (!isValidPhoneNumber) {
						errors["phone_number"] = intl.formatMessage(messages.phoneNumberInvalid);
					}
				}

				break;
			}

			default: {
				if (!!values.state && (values.state.length > 80 || values.state.length < 2)) {
					errors["state"] = intl.formatMessage(messages.stateInvalid);
				}
			}
		}


		return errors;
	}, [intl]);

	if (paymentInfo !== null) {
		return <Success/>
	}

	return (
		<Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validate} validateOnBlur validateOnChange>
			{({isSubmitting, errors}) => (
				<Form initialValues={initialValues} errors={errors} isSubmitting={isSubmitting} apiError={apiError}/>
			)}
		</Formik>
	)
};

export default BoletoForm
