import React, {useCallback, useContext, useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useHistory, useRouteMatch} from 'react-router';
import styled from 'styled-components';

import cards from 'constants/cards';
import SectionTitle from 'components/SectionTitle';
import SupportedCards from 'components/SupportedCards';
import {AppContext} from 'contexts/AppContext/AppContext';
import subscription from 'services/subscription';

import messages from './messages';
import EbanxCreditCardForm from 'components/EbanxCreditCardForm';
import {PaymentContext} from "../../../../contexts/PaymentContext/PaymentContext";
import {PAYMENT_METHODS} from "../../../../providers/PaymentProvider/constants";
import PageNotFound from 'components/PageNotFound';

const StyledWrapper = styled.div``;

export const Ebanx = () => {
	const { url } = useRouteMatch()!;
	const [paymentInfo, setPaymentInfo] = useState<PaymentInfo | undefined | null>();
	const [loading, setLoading] = useState(true);

	const { country, customer_id, redirect_url } = useContext(AppContext);
	const {getPaymentInfo} = useContext(PaymentContext);

	const history = useHistory();

	useEffect(() => {
		getPaymentInfo(PAYMENT_METHODS.EBANX)
			.then(payment_info => {
				setPaymentInfo(payment_info);
			})
			.catch(e => {
				// FIXME
				console.error(e.message);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [customer_id]);

	const email = paymentInfo ? paymentInfo.email : "";

	const onGetToken = useCallback(
		async (data: any) => {
			const {
				card: { token, payment_type_code, masked_card_number, expiration_date },
				values: { card_name, ...rest },
			} = data;

			const payLoad = {
				customer_id,
				email,
				country,
				token,
				payment_type_code,
				card_mask: masked_card_number,
				name: card_name,
				expiration_date,
				...rest,
			};
			try {
				await subscription.put("/v1/ebanx/card/", payLoad);
				if (redirect_url) {
					window.top.location.href = redirect_url;
				} else {
					history.push({pathname: `${url}/thank-you`, search: history.location.search})
				}
			} catch (err) {
				console.log("onGetToken handler:", err.message);
				throw err;
			}
		},
		[customer_id, country, email, redirect_url, url]
	);

	if (loading) {
		return null;
	} else if (paymentInfo === null) {
		return (
			<PageNotFound/>
		)
	} else {
		const { name, document, address, street_number, city, state, zipcode, phone_number } = paymentInfo!;

		const initialValues = {
			document,
			address,
			street_number,
			city,
			state,
			zipcode,
			phone_number,
			card_name: name,
			country: country!,
		};

		// @ts-ignore
		const supportedCars = cards[country];

		return !loading && !!paymentInfo ? (
			<StyledWrapper>
				<SectionTitle>
					<FormattedMessage {...messages.title} />
				</SectionTitle>
				<SupportedCards cards={supportedCars} />
				<EbanxCreditCardForm initialValues={initialValues} onGetTokenSuccess={onGetToken}/>
			</StyledWrapper>
		) : null;
	}
};
