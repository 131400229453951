import axios from "axios";

const baseURL = process.env.REACT_APP_SUBSCRIPTION_API_URL;

const subscription = axios.create({
    baseURL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

export default subscription;
