import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import messages from "./messages";

const Wrapper = styled.div`
	margin-bottom: 2rem;
`;

const Img = styled.img`
	width: 48px;
	margin-right: 4px;
	display: inline-block;
`;

const Label = styled.p`
	margin-top: 0;
	font-size: 12px;
`;

interface Card {
	name: string;
	src: any;
}
type Props = {
	cards: Card[];
};

const SupportedCards: React.FC<Props> = ({ cards }) => (
	<Wrapper>
		<Label>
			<FormattedMessage {...messages.label} />
		</Label>
		{cards.map(card => (
			<Img src={card.src} alt={card.name} key={card.name} />
		))}
	</Wrapper>
);

export default SupportedCards;
