import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    color: #721c24;
    background-color: #f8d7da;
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 2rem;
    border: 1px solid #f5c6cb;
    border-radius: .25rem;
`

type Props = {
  children: React.ReactChildren | string | null | undefined
}

const ErrorMessage:React.FC<Props> = ({children}) => {

  return (
    <Wrapper>
      {children}
    </Wrapper>
  )
}

export default ErrorMessage;
