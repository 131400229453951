import {useState, useEffect} from "react";
import {useFormikContext} from 'formik';

const useFormikSubmitted = () => {
	const [submitted, setSubmitted] = useState(false);
	const {isSubmitting} = useFormikContext();

	useEffect(() => {
		if (!submitted) {
			setSubmitted(isSubmitting);
		}
	}, [isSubmitting, submitted]);

	return submitted;
};

export default useFormikSubmitted;
