import {useEffect} from "react";
import {useFormikContext} from 'formik';
import { dataLayerPush } from '../services/dataLayer';

const useFormikLogOnFailEffect = () => {
	const {submitCount, isValid, errors} = useFormikContext();

	useEffect(() => {
		if (submitCount > 0 && !isValid) {
			Object.keys(errors).forEach(field => {
				dataLayerPush({event: 'checkoutEvents', category: 'Checkout', action: 'Validation error', label: field})
			})
		}
	}, [submitCount, isValid, errors]);
};

export default useFormikLogOnFailEffect;
