import { defineMessages } from "react-intl";

export const scope = 'app.ebanx.card.update.EbanxCard';

export default defineMessages({
    title: {
        id: `${scope}.title`,
        defaultMessage: 'EbanxCard information',
    },
});
