import {defineMessages} from "react-intl";

export const scope = 'app.payments.boleto';

export default defineMessages({
	boleto: {
		id: `${scope}.boleto`,
		defaultMessage: 'Boleto',
	},
	termsAndConditions: {
		id: `${scope}.termsAndConditions`,
		defaultMessage: 'By clicking "generate Boleto", you agree to the <terms>Terms & Conditions</terms> of UENI.',
	},
	pago: {
		id: `${scope}.pago`,
		defaultMessage: 'This boleto can be paid anywhere, but if it is paid at Banco do Brasil, Bradesco, Itaú, Caixa Econômica Federal or America, the payment will be confirmed within one business day. <info>More information</info>',
	},
});
