import React from 'react';
import {Field} from 'formik';

import {useIntl} from 'react-intl';

import {InputProps} from 'components/Input/types';
import Input from 'components/Input';

import useFormikSubmitted from "hooks/useFormikSubmitted";

import messages  from './messages';


type FormikInputProps = Partial<InputProps> & { withPlaceholder?: boolean };

const FormikField: React.FC<FormikInputProps> = (props) => {
	const {id, type = 'text', width, placeholder, withPlaceholder = false, svgicon, required, className, maxLength, mask, maskChar} = props;
	const intl = useIntl();

	// @ts-ignore
	let label = intl.formatMessage(messages[`label.${id!}`]);
	const submitted = useFormikSubmitted();

	if (required && label) {
		label += "*"
	}
	// @ts-ignore
	const localisedPlaceholder = withPlaceholder ? intl.formatMessage(messages[`placeholder.${id!}`], {placeholder}) : '';

	return (
		<Field type={type} name={id} width={width} placeholder={localisedPlaceholder} svgicon={svgicon}>
			{({
					field,
					form: {touched, errors},
				}: any) => {

				return (
					<div className={className}>
						<Input type={type}
									 id={id}
									 label={label}
									 width={width}
									 maxLength={maxLength}
									 placeholder={localisedPlaceholder}
									 {...field}
									 mask={mask}
									 maskChar={maskChar}
									 error={submitted && errors[id!]}
									 svgicon={svgicon}
									 focusOnError={Object.keys(errors)[0] === id} //only the first in the list
						/>
					</div>
				)
			}}
		</Field>
	)
};

export default FormikField
