import React from 'react';
import styled from 'styled-components'
import {FormattedMessage} from "react-intl";
import ConfettiSvg from 'assets/success.svg'
import messages from "./messages";
import { useParams } from 'react-router';


const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1 0 auto;
	line-height: 1.2;
	flex-direction: column;
`

const Title = styled.h1`
	font-size: 2rem;
	font-weight: 500;
`

const Confetti = styled.div`
	width: 113px;
	height: 180px;
	background-image: url(${ConfettiSvg});
	background-repeat: no-repeat;
	background-position: left 50% top 30px;
`

const Message = styled.span`
	font-weight: 400px;
	font-size: 1rem;
`

export default () => {

	const { action } = useParams();

	// @ts-ignore
	const title = messages[`${action}.title`];
	// @ts-ignore
	const text = messages[`${action}.text`];

	return (
		<Container>
			<Confetti/>
			<Title>
				<FormattedMessage {...title}/>
			</Title>
			<Message>
				<FormattedMessage {...text}/>
			</Message>
		</Container>
	)
}
