import React from 'react';
import styled from 'styled-components'
import {FormattedMessage} from "react-intl";

import messages from "./messages";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto;
    line-height: 1.2;
`

const Title404 = styled.h1`
    margin-right: 1rem;
    padding-right: 1rem;
    font-size: 2.5rem;
    font-weight: 500;
    border-right: 1px solid #ccc;
`

const Title = styled.h2`
    font-size: 1.2rem;
    font-weight: 400;
`

export default () => (
    <Container>
        <Title404>404</Title404>
        <Title>
            <FormattedMessage {...messages.title}/>
        </Title>
    </Container>
)
