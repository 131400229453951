import React from 'react';
import ReactDOM from 'react-dom';
import {Route, Switch} from "react-router";

import * as serviceWorker from './serviceWorker';
import PageNotFound from "./components/PageNotFound";
import {BrowserRouter} from "react-router-dom";
import locales from "./constants/locales";
import App from "./App";

const APP_LOCALES = locales.join('|');

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path={`/:locale(${APP_LOCALES})`}>
                <App/>
            </Route>
            <Route component={PageNotFound}/>
        </Switch>
    </BrowserRouter>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
