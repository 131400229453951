import React, {useEffect} from "react";
import {useParams} from "react-router";

import useQueryParams from "../../hooks/useQueryParams";

import subscription from "../../services/subscription";
import { AppContext } from "../../contexts";

const getLocaleAndCountry = (locale: string) => {
    const [language, country] = locale.split('-');
    return [`${language}-${country.toUpperCase()}`, country.toUpperCase()]
};

export default (props: any) => {

    // @ts-ignore
    const query = useQueryParams();
    const {locale} = useParams();
    const [isoLocale, country] = getLocaleAndCountry(locale!);

    const otp = query.get("otp");
    const customer_id = query.get("customer_id");
    const plan_code = query.get("plan_code");
    const secret = query.get("secret");
    const redirect_url = query.get("redirect_url");
    const zoho_coupon = query.get("zoho_coupon");
    const embedded = Boolean(query.get("embedded") || window !== window.top);
    const subscription_id = query.get("subscription_id");
    const addon_code = query.get("addon_code");


    useEffect(() => {
			if(secret) {
				subscription.defaults.headers.common["secret"] = secret;
			}
    }, []);


    return locale !== null ? (
			<AppContext.Provider value={{
				country,
				customer_id,
				plan_code,
				secret,
				redirect_url,
				zoho_coupon,
				query,
				embedded,
				otp,
				subscription_id,
				addon_code,
				locale: isoLocale,
			}}>
				{props.children}
			</AppContext.Provider>
		) : null
};
