import React from "react";
import {Route, Switch, useRouteMatch} from "react-router";
import PageNotFound from "../../../../components/PageNotFound";
import ThankYou from "../../../../components/ThankYou";

export const Ebanx = () => {
	const { path } = useRouteMatch();

	return (
        <Switch>
					<Route path={`${path}/thank-you`} component={ThankYou} exact/>
					<Route path="*" component={PageNotFound}/>
				</Switch>
    )
};
