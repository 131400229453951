const BASE_URL = "https://ueni.com";

export const getTermsPageLink = (locale:string = "") => {
	let slug;
	const lowercaseLocale = locale.toLowerCase();
	switch (lowercaseLocale) {
		case "es-es":
		case "es-pe":
		case "es-co":
		case "es-cl":
		case "es-mx":
			slug = "terminos-y-condiciones";
			break;
		case "pt-br":
			slug = "termos-e-condicoes";
			break;
		default:
			slug = "terms"
	}
	return `${BASE_URL}/${lowercaseLocale}/${slug}`;
}

export const getPrivacyPageLink = (locale:string = "") => {
	let slug;
	const lowercaseLocale = locale.toLowerCase();
	switch (lowercaseLocale) {
		case "es-es":
		case "es-pe":
		case "es-co":
		case "es-cl":
		case "es-mx":
			slug = "politica-de-privacidad";
			break;
		case "pt-br":
			slug = "politica-de-privacidade";
			break;
		default:
			slug = "privacy"
	}
	return `${BASE_URL}/${lowercaseLocale}/${slug}`;
}
