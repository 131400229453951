import React from "react";
import {Route, Switch, useRouteMatch} from "react-router";
import PageNotFound from "../../../../components/PageNotFound";
import Success from "./components/Success";
import Failure from "./components/Failure";

export const OnePay = () => {
	const { path } = useRouteMatch();

	return (
		<Switch>
			<Route path={`${path}/success`} component={Success} exact/>
			<Route path={`${path}/failure`} component={Failure} exact/>
			<Route path="*" component={PageNotFound}/>
		</Switch>
	)
};
