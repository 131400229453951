import React from 'react'
import styled, { keyframes } from 'styled-components'

const Text = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Container = styled.div`
  align-self: center;
  display: flex;
  width: 100%;
  flex-direction: column;
`

const Spinner = styled.div<{size: number}>`
  animation: ${rotate360} 1s linear infinite;
  box-shadow: 0 ${({ size = 40 }) => size / 40}px 0 0 #333333;
  align-self: center;
  width: ${({ size = 40 }) => size}px;
  height: ${({ size = 40 }) => size}px;
  border-radius: ${({ size = 40 }) => size / 2}px;
  margin: ${({ size = 40 }) => size / 4}px;
`

type LoadingPropsType = {
  text?: string,
  size?: number,
}

const Loading = ({ text, size = 200 }: LoadingPropsType) => {

  return (
    <Container>
      <Spinner size={ size }/>
      <Text>{text}</Text>
    </Container>
  )
}

export default Loading
