import { defineMessages } from "react-intl";

export const scope = 'app.ebanx.card.charge.EbanxCard';

export default defineMessages({
    title: {
        id: `${scope}.title`,
        defaultMessage: 'Payment',
    },
    useExistingCard: {
        id: `${scope}.useExistingCard`,
        defaultMessage: 'Use existing card - {cardMask}',
    },
    useAnotherCard: {
        id: `${scope}.useAnotherCard`,
        defaultMessage: 'Use another card',
    },
    pay: {
        id: `${scope}.pay`,
        defaultMessage: 'Pay',
    }
});
