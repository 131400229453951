import React from "react";
import styled from "styled-components";

export const Grid = styled.div<{
	container?: boolean,
	spacing?: number,
	spacingX?: number,
	spacingY?: number,
	item?: boolean,
	alignContent?:	'stretch'
		| 'center'
		| 'flex-start'
		| 'flex-end'
		| 'space-between'
		| 'space-around'
	alignItems?: 'flex-start'
		| 'center'
		| 'flex-end'
		| 'stretch'
		| 'baseline'
	justify?:	'flex-start'
		| 'center'
		| 'flex-end'
		| 'space-between'
		| 'space-around'
		| 'space-evenly'
	direction?:	'row'
		| 'row-reverse'
		| 'column'
		| 'column-reverse'
}>`
	display: flex;
	align-content: ${({alignContent}) => alignContent};
	justify-content: ${({justify}) => justify};
	align-items: ${({alignItems}) => alignItems};
	flex-direction: ${({direction}) => direction};

	& > * {
		margin: ${({spacing}) => spacing ? `${spacing}px` : undefined};
		&:not(:first-child){
			margin-left: ${({spacingX}) => spacingX ? `${spacingX}px` : undefined};
			margin-top: ${({spacingY}) => spacingY ? `${spacingY}px` : undefined};
		}

		&:not(:last-child){
			margin-right: ${({spacingX}) => spacingX ? `${spacingX}px` : undefined};
			margin-bottom: ${({spacingY}) => spacingY ? `${spacingY}px` : undefined};
		}

	}
`;


