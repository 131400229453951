import { useCallback, useContext } from "react";
import { AppContext } from "../contexts";

const options = {
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
}

export const useNumberFormat = () => {
	const {locale} = useContext(AppContext);
	const formatPrice = useCallback((count: number) => {
		return count.toLocaleString(locale || undefined, options);
	}, [locale]);

	return formatPrice;
};
