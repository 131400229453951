import React, {useState} from "react";
import {ReadyContext} from "contexts";

export const ReadyProvider = (props: any) => {
	const context = useState({
		summary: false,
		payments: false
	});

	return (
		<ReadyContext.Provider value={context}>
			{props.children}
		</ReadyContext.Provider>
	)
};
