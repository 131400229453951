export const DEFAULT_LOCALE = 'en-GB';

export enum COUNTRIES {
	"MX" = "MX",
	"BR" = "BR",
	"CL" = "CL",
	"CO" = "CO",
	"PE" = "PE",
	"VN" = "VN",
};

export default [
    'en-GB',
    'es-CL',
		'es-CO',
		'es-MX',
		'es-PE',
    'pt-BR',
	  'vi-VN',
]
