import React, { useCallback } from 'react'
import { FieldProps } from 'formik'
import Select from 'react-select'
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import messages from '../EbanxCreditCardForm/components/FormikField/messages';
import useFormikSubmitted from "../../hooks/useFormikSubmitted";

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 1rem;
`;

const StyledErrorMessage = styled.p`
    font-size: 0.8rem;
    margin: 0;
    color: ${({theme}) => theme.colors.danger};
    position: absolute;
    bottom: 0;
    white-space: pre;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
`;

const StyledLabel = styled.label`
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 0.25em;
`;

const StyledSelect = styled(Select)`
    margin-bottom: 1.1rem;
`;

interface SelectProps {
	label: string;
	options: Array<{ value: string; label: string }>;
	required?: boolean;
	onChange?: (data: any) => void;
}

const SelectField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
 	required,
	onChange,
  ...props
}: FieldProps & SelectProps) => {
  const { options } = props

  const intl = useIntl();
	const submitted = useFormikSubmitted();

  // @ts-ignore
  let label = intl.formatMessage(messages[`label.${field.name!}`]);

	if (required && label) {
		label += "*"
	}

  // @ts-ignore
  const placeholder = intl.formatMessage(messages[`placeholder.${field.name!}`]);

	const handleChange = useCallback((option) => {
		const value = (option as any).value;
		setFieldValue(field.name, value);
		onChange && onChange(value);
	}, [field.name, setFieldValue, onChange]);

	return (
    <StyledWrapper>
      <StyledLabel htmlFor={field.name}>{label}</StyledLabel>
      <StyledSelect
        {...field}
        {...props}
        options={options}
        value={(options ? options.find(option => option.value === field.value) : '') as any}
				// @ts-ignore
				onChange={handleChange}
        placeholder={placeholder}
      />
      {submitted && errors[field.name] && (
        <StyledErrorMessage>{errors[field.name]}</StyledErrorMessage>
      )}
    </StyledWrapper>
  )
}

export default SelectField
