import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import {isEmpty} from "lodash";
import {InputProps} from './types';
import InputMask from 'react-input-mask';

const inputCss = css<{svgicon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>}>`
	background: rgb(255, 255, 255);
	border-radius: 4px;
	border: 1px solid ${({theme}) => theme.colors.border};
	font-size: 1rem;
	padding: 0.5em 1em;
	margin-bottom: 1rem;
	outline: none;
	box-sizing: border-box;
	${({svgicon}) => svgicon && `
	background-image: url(${svgicon});
	background-repeat: no-repeat;
	background-position: right 5px top 8px;`}
`

const StyledMask = styled(InputMask)`
	${inputCss}
	width: ${({width}) => width || '100%'};
`;

const StyledInput = styled.input`
	${inputCss}
	width: ${({width}) => width || '100%'};
`;

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

const StyledLabel = styled.label`
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 0.25em;
`;

const StyledError = styled.p`
    font-size: 0.8rem;
    margin: 0;
    color: ${({theme}) => theme.colors.danger};
    position: absolute;
    bottom: 0;
    white-space: pre;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
`;

interface InputElement {
	getInputDOMNode: () => HTMLInputElement
}

const Input = (props: InputProps) => {
	const {
		id,
		label,
		error,
		width,
		maxLength,
		mask = "",
		maskChar = null,
		focusOnError,
		...other
	} = props;

	function isInputMask(el: HTMLInputElement | InputElement): el is InputElement {
		return (el as InputElement).getInputDOMNode !== undefined;
	}

	const myRef = useRef<any>(null)
	useEffect(() => {
		if (focusOnError && error && myRef.current) {
			if (isInputMask(myRef.current)) {
				myRef.current.getInputDOMNode().focus()
			} else {
				myRef.current.focus()
			}
		}
	}, [focusOnError, error])

	return (
		<StyledWrapper>
			{isEmpty(label) ? null : (
				<StyledLabel htmlFor={id}>
					{label}
				</StyledLabel>
			)}
			{
				mask
				? <StyledMask
						ref={myRef as any}
						id={id}
						width={width}
						{...other}
						mask={mask}
						maskChar={maskChar}
					/>
				: <StyledInput ref={myRef as any} id={id} width={width} maxLength={maxLength} {...other}/>
			}
			{
				isEmpty(error) ? null : (
					<StyledError>{error}</StyledError>
				)
			}

		</StyledWrapper>
	)
};

export default Input;
