import Amex from "../assets/ebanx-amex.png";
import Aura from "../assets/ebanx-aura.png";
import Carnet from "../assets/ebanx-carnet.png";
import Diners from "../assets/ebanx-diners.png";
import Discover from "../assets/ebanx-discover.png";
import Elo from "../assets/ebanx-elo.png";
import Hiper from "../assets/ebanx-hiper.png";
import Mastercard from "../assets/ebanx-mastercard.png";
import Maestro from "../assets/ebanx-maestro.png";
import Visa from "../assets/ebanx-visa.png";
import Magna from "../assets/ebanx-magna.png";

const cards = {
	amex: {name: "Amex", src: Amex},
	aura: {name: "Aura", src: Aura},
	carnet: {name: "Carnet", src: Carnet},
	diners: {name: "Diners", src: Diners},
	discover: {name: "Discover", src: Discover},
	elo: {name: "Elo", src: Elo},
	hiper: {name: "Hiper", src: Hiper},
	mastercard: {name: "Mastercard", src: Mastercard},
	maestro: {name: "Maestro", src: Maestro},
	visa: {name: "Visa", src: Visa},
	magna: {name: "Magna", src: Magna},
}

export default {
	BR: [cards.mastercard, cards.visa, cards.discover, cards.diners, cards.hiper, cards.aura, cards.elo],
	CO: [cards.mastercard, cards.maestro, cards.visa, cards.amex, cards.diners],
	CL: [cards.mastercard, cards.visa, cards.amex, cards.diners, cards.magna],
	MX: [cards.mastercard, cards.visa, cards.amex, cards.carnet],
	PE: [cards.mastercard, cards.maestro, cards.visa, cards.amex, cards.diners],
	UK: [],
};
