export default {
    colors: {
        info: "#15b2f7",
        primary: "#ff4900",
        secondary: "#0fedb8",
        text: "#333333",
        border: "#d6d6d6",
        background: "#f5f5f5",
        danger: "#ff4a00",
        headerBackground: "#333333",
        footerBackground: "#333333",
    },

    fonts: {
        regular: "Lato-Regular, sans-serif",
        heading: "Montserrat-Black, sans-serif",
        subheading: "Montserrat-Medium, sans-serif",
    }

}
