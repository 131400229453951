import React, { useContext, useState, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { isEmpty, capitalize, find } from "lodash";
import styled from "styled-components";

import { AppContext } from "contexts/AppContext/AppContext";
import { SummaryContext } from "contexts/SummaryContext/SummaryContext";
import { Total } from "../../contexts/SummaryContext/types";
import Button from "../Button";
import Input from "../Input";
import SectionTitle from "../SectionTitle";
import messages from "./messages";
import { useNumberFormat } from "../../hooks/useNumberFormat";

const StyledSummaryWrapper = styled.div`
	margin-bottom: 2rem;
`;

const StyledPlanName = styled.h2`
	font-weight: 600;
	margin: 0;
	font-size: 1rem;
	line-height: 1.5em;
`;

const StyledPlanDescription = styled.p`
	font-weight: normal;
	margin: 0;
	font-size: 1rem;
`;

const StyledPlanInfoWrapper = styled.div`
	margin-bottom: 1rem;
`;

const StyledPaymentInfoWrapper = styled.div``;

const StyledPaymentItem = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
`;

const StyledTotalWrapper = styled(StyledPaymentItem)`
	margin-bottom: 2rem;
`;

const StyledPaymentLabel = styled.span`
	font-weight: bold;
	color: ${({ theme, color }) => theme.colors[color || "text"]};
`;

const StyledPaymentAmount = styled.span`
	color: ${({ theme, color }) => theme.colors[color || "text"]};
`;

const StyledDivider = styled.hr`
	border-top-color: ${({ theme }) => theme.colors.border};
	margin-bottom: 1rem;
`;

const StyledInputWrapper = styled.div`
	margin-right: 1rem;
	width: 100%;
	@media screen and (min-width: 480px) {
		flex: 1 0 65%;
	}
`;

const StyledFormGroup = styled.div`
	display: flex;
	flex-direction: column;
	@media screen and (min-width: 480px) {
		flex-direction: row;
	}
`

const OrderSummary = () => {
	const intl = useIntl();
	const appContext = useContext(AppContext);
	const summaryContext = useContext(SummaryContext);
	const formatPrice = useNumberFormat();

	const {
		loading,
		compute,
		couponCode,
		setCouponCode,
		isCouponCodeValid,
		instalmentsCount,
	} = summaryContext;

	const { plan_code } = appContext;


	const [coupon, setCoupon] = useState(couponCode);


	const onCouponChange = useCallback(e => {
		setCoupon(e.target.value);
	}, []);

	const haveCouponPlaceholder = intl.formatMessage(messages.haveCoupon);
	const invalidCouponMessage = intl.formatMessage(messages.invalidCoupon);

	if (compute === null) return null;

	// @ts-ignore
	const {
		plan: { billing_interval, billing_cycle, name },
		currency_symbol,
		payment_instalments,
		addon,
	} = compute;
	const fromInstalments = instalmentsCount > 1 && !!payment_instalments;
	const summary = fromInstalments ? find(payment_instalments, ['instalments', instalmentsCount]) : compute;
	const { tax, subtotal, credits_total, discount_total, total } = summary as Total;

	const tax_name = tax && tax.tax_name;
	const tax_type = tax && tax.tax_type;
	const tax_percentage = tax && tax.tax_percentage;
	const tax_amount = tax && tax.tax_amount;

	const onTimeInterval = intl.formatMessage(messages.onTimeInterval);
	const monthlyInterval = intl.formatMessage(messages.monthlyInterval);
	const annualSecondaryInterval = intl.formatMessage(messages.annualSecondaryInterval);
	let planName = "";
	// Check if key is in translation messages and fallback to plan name if it's absent
	if(addon){
		// @ts-ignore
		const maybePlanName = messages[`${addon.addon_code}_ordersummary_name`];
		planName = !!maybePlanName ? intl.formatMessage(maybePlanName) : addon.name;
	} else {

		// @ts-ignore
		const maybePlanName = messages[`${plan_code}_ordersummary_name`];
		planName = !!maybePlanName ? intl.formatMessage(maybePlanName) : name;
	}

	return (
		<StyledSummaryWrapper>
			<SectionTitle>
				<FormattedMessage {...messages.title} />
			</SectionTitle>
			<StyledPlanInfoWrapper>
				<StyledPlanName>
					{planName}
				</StyledPlanName>
				<StyledPlanDescription>
					{billing_interval === 12 && billing_cycle === -1 && `${currency_symbol}${formatPrice(subtotal)}${annualSecondaryInterval}`}
					{billing_interval === 1 && billing_cycle === -1 && `${currency_symbol}${formatPrice(subtotal)}${monthlyInterval}`}
					{billing_interval === 360000 && billing_cycle === 1 && `${currency_symbol}${formatPrice(subtotal)}${onTimeInterval}`}
				</StyledPlanDescription>
			</StyledPlanInfoWrapper>
			<StyledPaymentInfoWrapper>
				<StyledPaymentItem>
					<StyledPaymentLabel>
						<FormattedMessage {...messages.subtotal} />
					</StyledPaymentLabel>
					<StyledPaymentAmount>
						{currency_symbol}
						{formatPrice(subtotal)}
					</StyledPaymentAmount>
				</StyledPaymentItem>

				{credits_total > 0 && (
					<StyledPaymentItem>
						<StyledPaymentLabel color="info">
							<FormattedMessage {...messages.credit} />
						</StyledPaymentLabel>
						<StyledPaymentAmount color="info">
							-{currency_symbol}
							{formatPrice(credits_total)}
						</StyledPaymentAmount>
					</StyledPaymentItem>
				)}

				{discount_total > 0 && (
					<StyledPaymentItem>
						<StyledPaymentLabel color="info">
							<FormattedMessage {...messages.discount} />
						</StyledPaymentLabel>
						<StyledPaymentAmount color="info">
							-{currency_symbol}
							{formatPrice(discount_total)}
						</StyledPaymentAmount>
					</StyledPaymentItem>
				)}

				{tax_amount > 0 && (
					<StyledPaymentItem>
						<StyledPaymentLabel>
							{tax_name || capitalize(tax_type)}({tax_percentage}%)
						</StyledPaymentLabel>
						<StyledPaymentAmount>
							+{currency_symbol}
							{formatPrice(tax_amount)}
						</StyledPaymentAmount>
					</StyledPaymentItem>
				)}

				<StyledDivider />

				<StyledTotalWrapper>
					<StyledPaymentLabel className="uppercase">
						<FormattedMessage {...messages.total} />
					</StyledPaymentLabel>
					<StyledPaymentAmount>
						{currency_symbol}
						{formatPrice(total)}
					</StyledPaymentAmount>
				</StyledTotalWrapper>
				<StyledFormGroup>
					<StyledInputWrapper>
						<Input
							type="text"
							error={isCouponCodeValid ? undefined : invalidCouponMessage}
							value={coupon}
							onChange={onCouponChange}
							placeholder={haveCouponPlaceholder}
						/>
					</StyledInputWrapper>

					<Button
						disabled={isEmpty(coupon) || loading}
						fullwidth
						onClick={() => setCouponCode(coupon)} loading={loading && coupon !== ""}
						analyticsEvent={{event: 'checkoutEvents', category: 'Coupon', action: 'apply', label: coupon }}
					>
						<FormattedMessage {...messages.apply} />
					</Button>
				</StyledFormGroup>
			</StyledPaymentInfoWrapper>
		</StyledSummaryWrapper>
	)
};

export default OrderSummary;
