import axios from "axios";

const baseURL = process.env.REACT_APP_EBANX_API_URL || 'https://sandbox.ebanxpay.com/';

const ebanxApi = axios.create({
    baseURL,
		/* For cross-domain requests, setting the content type to anything other than
		application/x-www-form-urlencoded, multipart/form-data, or text/plain will trigger
		the browser to send a preflight OPTIONS request to the server.
		By default content type is application/json, which is trying to send an OPTION request
		which results into an error:
		'Request header field content-type is not allowed by Access-Control-Allow-Headers in preflight response.'
		*/
		headers : {
			'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
		}
});

export default ebanxApi;
