/*
 * Ebanx Messages
 *
 * This contains all the text for the Ebanx container.
 */
import {defineMessages} from "react-intl";

export const scope = 'app.ebanx.card.components.CreditCardForm';

export default defineMessages({
	submit: {
		id: `${scope}.submit`,
		defaultMessage: 'Pay',
	},
	confirm: {
		id: `${scope}.confirm`,
		defaultMessage: 'Confirm',
	},
	cardNumberRequired: {
		id: `${scope}.cardNumberRequired`,
		defaultMessage: 'Invalid card number',
	},
	cardNumberInvalid: {
		id: `${scope}.cardNumberInvalid`,
		defaultMessage: 'Invalid card number',
	},
	cardNotSupported: {
		id: `${scope}.cardNotSupported`,
		defaultMessage: 'Card is not supported',
	},
	cardNameRequired: {
		id: `${scope}.cardNameRequired`,
		defaultMessage: "Required field",
	},
	cardNameTooShort: {
		id: `${scope}.cardNameTooShort`,
		defaultMessage: "Cardholder name too short",
	},
	cardNameTooLong: {
		id: `${scope}.cardNameTooLong`,
		defaultMessage: "Cardholder name too long",
	},
	cardDueDateRequired: {
		id: `${scope}.cardDueDateRequired`,
		defaultMessage: "Required",
	},
	cardDueDateInvalid: {
		id: `${scope}.cardDueDateInvalid`,
		defaultMessage: "Incorrect due date",
	},
	cardDueDateExpired: {
		id: `${scope}.cardDueDateExpired`,
		defaultMessage: "Card expired",
	},
	cvvCodeRequired: {
		id: `${scope}.cvvCodeRequired`,
		defaultMessage: "Required",
	},
	cvvCodeInvalid: {
		id: `${scope}.cvvCodeInvalid`,
		defaultMessage: "Incorrect CVV",
	},
	documentIdInvalid: {
		id: `${scope}.documentIdInvalid`,
		defaultMessage: "Please, provide a valid document number",
	},
	stateInvalid: {
		id: `${scope}.stateInvalid`,
		defaultMessage: "Please, select your State",
	},
	cityInvalid: {
		id: `${scope}.cityInvalid`,
		defaultMessage: "Please, provide your City",
	},
	addressInvalid: {
		id: `${scope}.addressInvalid`,
		defaultMessage: "Please, provide your Street name",
	},
	streetNumberInvalid: {
		id: `${scope}.streetNumberInvalid`,
		defaultMessage: "Please, provide your House number",
	},
	zipcodeInvalid: {
		id: `${scope}.zipcodeInvalid`,
		defaultMessage: "Please, provide a valid Postcode",
	},
	phoneNumberInvalid: {
		id: `${scope}.phoneNumberInvalid`,
		defaultMessage: "Please, provide a valid Phone number",
	},
	'500': {
		id: `${scope}.500`,
		defaultMessage: "Your card was declined. Please contact your credit card issuer or use another card.",
	},
	'1000': {
		id: `${scope}.1000`,
		defaultMessage: "Insufficient funds. Please check your account or use another credit card.",
	},
	'1500': {
		id: `${scope}.1500`,
		defaultMessage: "Your personal details where incorrect. Please review your details.",
	},
	'2000': {
		id: `${scope}.2000`,
		defaultMessage: "Your card has expired. Please use another card",
	},
	'2500': {
		id: `${scope}.2500`,
		defaultMessage: "Security card mismatch. Please check the CVV of your card",
	},
	'3000': {
		id: `${scope}.3000`,
		defaultMessage: "The card is invalid. Please contact your credit card provider",
	},
	'3500': {
		id: `${scope}.3500`,
		defaultMessage: "Invalid card number. Please review your card number",
	},
	'4000': {
		id: `${scope}.4000`,
		defaultMessage: "The card is invalid. Please contact your credit card provider",
	},
	'4420': {
		id: `${scope}.4420`,
		defaultMessage: "We are already processing your payment, please check your email for confirmation of payment",
	},
	'4500': {
		id: `${scope}.4500`,
		defaultMessage: "There was a temporary issue. Please try again.",
	},
	'5000': {
		id: `${scope}.5000`,
		defaultMessage: "There was an unknown problem. Please contact support.",
	},
	'5500': {
		id: `${scope}.5500`,
		defaultMessage: "Your card Expiry date is invalid. Please check the expiry date of your card",
	},
	'BP-ZIP-1': {
		id: `${scope}.BP-ZIP-1`,
		defaultMessage: "Please provide a postcode. A postcode is required",
	},
	'BP-ZIP-2': {
		id: `${scope}.BP-ZIP-2`,
		defaultMessage: "Postcode invalid. Please check your postcode",
	},
	'BP-ZIP-3': {
		id: `${scope}.BP-ZIP-3`,
		defaultMessage: "Address invalid. Please make sure that all the provided address fields are correct",
	},
	'BP-DR-22': {
		id: `${scope}.BP-DR-22`,
		defaultMessage: "CPF is required. Please provide your CPF",
	},
	'BP-DR-23': {
		id: `${scope}.BP-DR-23`,
		defaultMessage: "Invalid CPF. please check your CPF number",
	},
	'BP-DR-24': {
		id: `${scope}.BP-DR-24`,
		defaultMessage: "Postcode is required. Please fill the postcode.",
	},
	'BP-DR-25': {
		id: `${scope}.BP-DR-25`,
		defaultMessage: "Street address is required. Please add your street address.",
	},
	'BP-DR-26': {
		id: `${scope}.BP-DR-26`,
		defaultMessage: "Street number is required. Please add your street number.",
	},
	'BP-DR-27': {
		id: `${scope}.BP-DR-27`,
		defaultMessage: "City is required. Please add your city.",
	},'BP-DR-28': {
		id: `${scope}.BP-DR-28`,
		defaultMessage: "State is required. Please add your state.",
	},
	'BP-DR-29': {
		id: `${scope}.BP-DR-29`,
		defaultMessage: "Invalid state. Please provide the correct state.",
	},
	'BP-DR-31': {
		id: `${scope}.BP-DR-31`,
		defaultMessage: "Phone number is required. Please provide your phone number",
	},
	'BP-DR-32': {
		id: `${scope}.BP-DR-32`,
		defaultMessage: "Phone number invalid. Please check your phonenumber",
	},
	'BP-DR-39': {
		id: `${scope}.BP-DR-39`,
		defaultMessage: "CPF, name and birth date do not match. Please provide the correct CPF",
	},
	'BP-DR-111': {
		id: `${scope}.BP-DR-111`,
		defaultMessage: "Invalid CPF. Please provide a valid CPF",
	},
	'BP-DR-48': {
		id: `${scope}.BP-DR-48`,
		defaultMessage: "Credit card is required. Please provide your credit card",
	},
	'BP-DR-49': {
		id: `${scope}.BP-DR-49`,
		defaultMessage: "Invalid card number. Please review your card number",
	},
	'BP-DR-51': {
		id: `${scope}.BP-DR-51`,
		defaultMessage: "Invalid card holder name. Please make sure your name is correct",
	},
	'BP-DR-54': {
		id: `${scope}.BP-DR-54`,
		defaultMessage: "Security card mismatch. Please check the CVV of your card",
	},
	'BP-DR-55': {
		id: `${scope}.BP-DR-55`,
		defaultMessage: "Security card mismatch. Please check the CVV of your card",
	},
	'BP-DR-56': {
		id: `${scope}.BP-DR-56`,
		defaultMessage: "Invalid expiry date. Please check the expiry date on your card",
	},
	'BP-DR-61': {
		id: `${scope}.BP-DR-61`,
		defaultMessage: "There was a problem with your card. please contact your card provider.",
	},
	'BP-DR-67': {
		id: `${scope}.BP-DR-67`,
		defaultMessage: "Invalid expiry date. Please check the expiry date on your card",
	},
	'BP-DR-75': {
		id: `${scope}.BP-DR-75`,
		defaultMessage: "Invalid card number. Please review your card number",
	},
	'BP-DR-83': {
		id: `${scope}.BP-DR-83`,
		defaultMessage: "This card cannot be used in this country. Please use another card.",
	},
	'BP-DR-95': {
		id: `${scope}.BP-DR-95`,
		defaultMessage: "Invalid card holder name. Please make sure your name is correct",
	},
	'BP-DR-101': {
		id: `${scope}.BP-DR-101`,
		defaultMessage: "This card cannot be used online. Please contact your card provider or use another card.",
	},
	'BP-DR-102': {
		id: `${scope}.BP-DR-102`,
		defaultMessage: "You have made too many attempts with this card recently, please try again later.",
	},
	'BP-DR-112': {
		id: `${scope}.BP-DR-112`,
		defaultMessage: "Insufficient funds. Please check your account or use another credit card.",
	},
	'BP-DR-113': {
		id: `${scope}.BP-DR-113`,
		defaultMessage: "This CPF has been blocked. You cannot do transactions with this CPF. Please contact support.",
	},
	'BP-DR-18': {
		id: `${scope}.BP-DR-18`,
		defaultMessage: "Customer is disabled on EBANX",
	},
	'BP-DR-103': {
		id: `${scope}.BP-DR-103`,
		defaultMessage: "Customer disabled",
	},
	'BP-DR-104': {
		id: `${scope}.BP-DR-104`,
		defaultMessage: "This card cannot be used in this country. Please use another card.",
	}
});

