import React, { useCallback, useContext } from "react";
import Select from 'react-select'
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import messages from '../EbanxCreditCardForm/components/FormikField/messages';
import { SummaryContext } from "../../contexts";
import { sortBy } from "lodash";
import { dataLayerPush } from "../../services/dataLayer";
import { useNumberFormat } from "../../hooks/useNumberFormat";

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 1rem;
`;

const StyledLabel = styled.label`
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 0.25em;
`;

const StyledSelect = styled(Select)`
    margin-bottom: 1.1rem;
`;

const analyticsEvent = {
	event: 'checkoutEvents',
	category: 'Checkout',
	action: 'Instalments change',
};

const SelectField = () => {
	const intl = useIntl();
	const { compute, instalmentsCount, setInstalmentsCount } = useContext(SummaryContext);
	const formatPrice = useNumberFormat();
	const instalments = compute && compute.payment_instalments;
	const currencySymbol = compute && compute.currency_symbol;
	const total = compute && compute.total;
	const handleChange = useCallback((option) => {
		const value = (option as any).value;
		dataLayerPush({ ...analyticsEvent, label: value });
		setInstalmentsCount(value);
	}, [setInstalmentsCount]);

	if(!instalments) return null

  // @ts-ignore
  let label = intl.formatMessage(messages['label.instalments']);
  // @ts-ignore
  const placeholder = intl.formatMessage(messages['placeholder.instalments']);


	const instalmentsOptions = instalments.map(({ instalments, total }) => ({
		label: `${instalments} x ${currencySymbol} ${formatPrice(total/instalments)} Total: ${currencySymbol} ${formatPrice(total)}`,
		value: instalments
	}));

	instalmentsOptions.push({
		label: `1 x ${currencySymbol}${formatPrice(total as number)}`,
		value: 1,
	});

	return (
    <StyledWrapper>
      <StyledLabel>{label}</StyledLabel>
      <StyledSelect
        options={sortBy(instalmentsOptions, 'value')}
        value={instalmentsOptions.find(option => option.value === instalmentsCount)}
				onChange={handleChange}
        placeholder={placeholder}
      />
    </StyledWrapper>
  )
}

export default SelectField
