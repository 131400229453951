import {defineMessages} from "react-intl";

export const scope = 'app.payments.boleto.form';

export default defineMessages({
	pay: {
		id: `${scope}.pay`,
		defaultMessage: 'Pay',
	},
	nameInvalid: {
		id: `${scope}.nameInvalid`,
		defaultMessage: 'Required field',
	},
	documentInvalid: {
		id: `${scope}.documentInvalid`,
		defaultMessage: "Please, provide a valid document number",
	},
	stateInvalid: {
		id: `${scope}.stateInvalid`,
		defaultMessage: "Please, select your State",
	},
	cityInvalid: {
		id: `${scope}.cityInvalid`,
		defaultMessage: "Please, provide your City",
	},
	addressInvalid: {
		id: `${scope}.addressInvalid`,
		defaultMessage: "Please, provide your Street name",
	},
	streetNumberInvalid: {
		id: `${scope}.streetNumberInvalid`,
		defaultMessage: "Please, provide your House number",
	},
	zipcodeInvalid: {
		id: `${scope}.zipcodeInvalid`,
		defaultMessage: "Please, provide a valid Postcode",
	},
	phoneNumberInvalid: {
		id: `${scope}.phoneNumberInvalid`,
		defaultMessage: "Please, provide a valid Postcode",
	},
});
