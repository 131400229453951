import { defineMessages } from "react-intl";

export const scope = 'app.payments.oxxo';

export default defineMessages({
	payDatesInfo: {
		id: `${scope}.payDatesInfo`,
		defaultMessage: 'You will have 3 days to pay for your voucher',
	},
	paySubscriptionInfo: {
		id: `${scope}.paySubscriptionInfo`,
		defaultMessage: 'Once we receive payment, your subscription will be upgraded',
	},
	pay: {
		id: `${scope}.pay`,
		defaultMessage: 'Pay',
	},
	upgrade: {
		id: `${scope}.upgrade`,
		defaultMessage: 'Upgrade now',
	},
	show: {
		id: `${scope}.show`,
		defaultMessage: 'Show my Oxxo',
	},
	termsAndConditions: {
		id: `${scope}.termsAndConditions`,
		defaultMessage: 'By continuing you agree to the <terms>Terms & Conditions</terms> and <privacy>Privacy Policy</privacy>.',
	}
});
