import { defineMessages } from "react-intl";

export const scope = 'app.onepay.card.charge.EbanxCard';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'Payment',
	},
	internationalCard: {
		id: `${scope}.internationalCard`,
		defaultMessage: 'International credit card',
	},
	domesticCard: {
		id: `${scope}.domesticCard`,
		defaultMessage: 'Domestic credit card & e-wallet',
	},
  pay: {
		id: `${scope}.pay`,
    defaultMessage: 'Pay',
	}
});
