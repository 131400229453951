import { defineMessages } from "react-intl";

export const scope = 'app.onepay.card.charge.Failure';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'Something went wrong',
	},
	text: {
		id: `${scope}.text`,
		defaultMessage: 'Please contact support',
	},
	link: {
		id: `${scope}.link`,
		defaultMessage: 'Back to UENI.com',
	},
});
