import React from 'react';
import styled from 'styled-components'
import {FormattedMessage} from "react-intl";
import FailureSvg from 'assets/circle-error.svg'
import messages from "./messages";

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1 0 auto;
	line-height: 1.2;
	flex-direction: column;
`

const Title = styled.h1`
	font-size: 2rem;
	font-weight: 800;
	text-align: center;
`

const Message = styled.span`
	font-weight: 500px;
	font-size: 1rem;
	margin-top: 1.5rem;
`

const Icon = styled.div`
	width: 95px;
	height: 95px;
	background-image: url(${FailureSvg});
	background-repeat: no-repeat;
`

const A = styled.a`
	color: #15b2f7;
	font-weight: normal;
	font-size: 0.75rem;
	text-decoration: underline;
	text-align: center;
	line-height: 16px;
	margin-top: 2.5rem;
`

export default () => {
	return (
		<Container>
			<Icon/>
			<Title>
				<FormattedMessage {...messages.title}/>
			</Title>
			<Message>
				<FormattedMessage {...messages.text}/>
			</Message>
			<A href="https://ueni.com" target="_top">
				<FormattedMessage {...messages.link}/>
			</A>
		</Container>
	)
}
