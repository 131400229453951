import React, {useState} from "react";
import {ErrorContext} from "contexts";

export const ErrorProvider = (props: any) => {
	const context = useState<string | null>(null);

	return (
		<ErrorContext.Provider value={context}>
			{props.children}
		</ErrorContext.Provider>
	)
};
