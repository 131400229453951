import { defineMessages } from "react-intl";

export const scope = "app.components.OrderSummary";

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: "Order Summary",
	},
	subtotal: {
		id: `${scope}.subtotal`,
		defaultMessage: "Subtotal",
	},
	credit: {
		id: `${scope}.credit`,
		defaultMessage: "Credit",
	},
	discount: {
		id: `${scope}.discount`,
		defaultMessage: "Discount",
	},
	tax: {
		id: `${scope}.tax`,
		defaultMessage: "Tax",
	},
	total: {
		id: `${scope}.total`,
		defaultMessage: "Total",
	},
	apply: {
		id: `${scope}.apply`,
		defaultMessage: "Apply",
	},
	onTimeInterval: {
		id: `${scope}.onTimeInterval`,
		defaultMessage: "",
	},
	monthlyInterval: {
		id: `${scope}.monthlyInterval`,
		defaultMessage: "/month",
	},
	annualPrimaryInterval: {
		id: `${scope}.annualPrimaryInterval`,
		defaultMessage: "/month x 12 months",
	},
	annualSecondaryInterval: {
		id: `${scope}.annualSecondaryInterval`,
		defaultMessage: "/year",
	},
	haveCoupon: {
		id: `${scope}.haveCoupon`,
		defaultMessage: "Have a coupon code?",
	},
	invalidCoupon: {
		id: `${scope}.invalidCoupon`,
		defaultMessage: "Coupon code is invalid",
	},
	"2018_basic_annual_ordersummary_name": {
		id: `${scope}.2018_basic_annual_ordersummary_name`,
		defaultMessage: "Basic Annual",
	},
	"2018_basic_monthly_ordersummary_name": {
		id: `${scope}.2018_basic_monthly_ordersummary_name`,
		defaultMessage: "Basic Monthly",
	},
	"2018_plus_annual_ordersummary_name": {
		id: `${scope}.2018_plus_annual_ordersummary_name`,
		defaultMessage: "Plus Annual",
	},
	"2018_plus_monthly_ordersummary_name": {
		id: `${scope}.2018_plus_monthly_ordersummary_name`,
		defaultMessage: "Plus Monthly",
	},
	"2019_basic_annual_ordersummary_name": {
		id: `${scope}.2019_basic_annual_ordersummary_name`,
		defaultMessage: "Basic Annual",
	},
	"2019_basic_monthly_ordersummary_name": {
		id: `${scope}.2019_basic_monthly_ordersummary_name`,
		defaultMessage: "Basic Monthly",
	},
	"2019_plus_annual_ordersummary_name": {
		id: `${scope}.2019_plus_annual_ordersummary_name`,
		defaultMessage: "Plus Annual",
	},
	"2019_plus_monthly_ordersummary_name": {
		id: `${scope}.2019_plus_monthly_ordersummary_name`,
		defaultMessage: "Plus Monthly",
	},
	"2020_lite_annual_ordersummary_name": {
		id: `${scope}.2020_lite_annual_ordersummary_name`,
		defaultMessage: "Lite",
	},
	"2020_reputation_annual_ordersummary_name": {
		id: `${scope}.2020_reputation_annual_ordersummary_name`,
		defaultMessage: "Reputation Annual",
	},
	"2020_reputation_monthly_ordersummary_name": {
		id: `${scope}.2020_reputation_monthly_ordersummary_name`,
		defaultMessage: "Reputation Monthly",
	},
	"connect-domain-addon_ordersummary_name": {
		id: `${scope}.connect-domain-addon_ordersummary_name`,
		defaultMessage: "Connect Domain",
	},
	"directories-addon_ordersummary_name": {
		id: `${scope}.directories-addon_ordersummary_name`,
		defaultMessage: "Listings",
	},
	"logo-addon_ordersummary_name": {
		id: `${scope}.logo-addon_ordersummary_name`,
		defaultMessage: "Logo",
	},
	"seo-audit-addon_ordersummary_name": {
		id: `${scope}.seo-audit-addon_ordersummary_name`,
		defaultMessage: "Seo Audit",
	},
	"transfer-out-addon_ordersummary_name": {
		id: `${scope}.transfer-out-addon_ordersummary_name`,
		defaultMessage: "Transfer out a Domain",
	},
	"ueni-domain_ordersummary_name": {
		id: `${scope}.ueni-domain_ordersummary_name`,
		defaultMessage: "Domain",
	},
	"ueni-email_ordersummary_name": {
		id: `${scope}.ueni-email_ordersummary_name`,
		defaultMessage: "Email",
	},
	"domain-renewal-one-year_ordersummary_name": {
		id: `${scope}.domain-renewal-one-year_ordersummary_name`,
		defaultMessage: "Domain renewal one year",
	},
});
