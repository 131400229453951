import { defineMessages } from "react-intl";

export const scope = "app.components.TermsAndConditions";

export default defineMessages({
	text: {
		id: `${scope}.text`,
		defaultMessage: "By clicking \"{button}\" you agree to the <terms>Terms & Conditions</terms> and <privacy>Privacy Policy</privacy>.",
	},
});
