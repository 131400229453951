import { defineMessages } from "react-intl";

export const scope = 'app.ebanx.card.components.ThankYou';

export default defineMessages({
    'update.title': {
        id: `${scope}.update.title`,
        defaultMessage: 'Thank You!',
    },
		'update.text': {
			id: `${scope}.update.text`,
			defaultMessage: 'You have updated your card successfully.',
		},
		'charge.title': {
			id: `${scope}.charge.title`,
			defaultMessage: 'Thank You!',
		},
		'charge.text': {
			id: `${scope}.charge.text`,
			defaultMessage: 'Your payment was successful.',
		},
});
