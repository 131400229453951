import React, {useCallback, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {FormattedMessage, useIntl} from "react-intl";
import {omitBy} from "lodash";

import cards from 'constants/cards';
import Button from "components/Button";
import ErrorMessage from "components/ErrorMessage";
import SupportedCards from 'components/SupportedCards';
import TermsAndConditions from 'components/TermsAndConditions';
import subscription from "services/subscription";
import { dataLayerPush } from "services/dataLayer";

import errors from "../../../../../../components/EbanxCreditCardForm/messages"
import messages from "./messages";
import {useHistory} from 'react-router';
import {AppContext, PaymentContext, SummaryContext} from "../../../../../../contexts";
import {PAYMENT_METHODS} from "../../../../../../providers/PaymentProvider/constants";
import EbanxCreditCardForm from "../../../../../../components/EbanxCreditCardForm";
import InstalmentSelect from "../../../../../../components/InstalmentSelect";
import { isEmptyValue } from "../../../../../../utils/isEmptyValue";

const StyledWrapper = styled.div<{
	active: boolean
}>`
	padding: 1rem 0;
	display: ${({active}) => active ? "block" : "none"};
`;

const CURRENT_PAYMENT_METHOD = "CURRENT_PAYMENT_METHOD";
const NEW_PAYMENT_METHOD = "NEW_PAYMENT_METHOD";

const StyledMethodRow = styled.div`
	margin-bottom: 1rem;
`;
const StyledMethodLabel = styled.label`
	cursor: pointer;
`;

const StyledMethodContainer = styled.div`
	margin-bottom: 2rem;
`;

const StyledRadio = styled.input`
	&:checked,
	&:not(:checked) {
		position: absolute;
		left: -9999px;
	}
	&:checked + label,
	&:not(:checked) + label {
		position: relative;
		padding-left: 34px;
		cursor: pointer;
		line-height: 24px;
		display: inline-block;
		color: ${({ theme }) => theme.colors.text};
	}
	&:not(:checked) + label:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 20px;
		height: 20px;
		border: 2px solid ${({ theme }) => theme.colors.border};
		border-radius: 100%;
		background: #fff;
	}
	&:checked + label:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 20px;
		height: 20px;
		border: 2px solid ${({ theme }) => theme.colors.info};
		border-radius: 100%;
		background: #fff;
	}
	&:checked + label:after,
	&:not(:checked) + label:after {
		content: "";
		width: 12px;
		height: 12px;
		background: ${({ theme }) => theme.colors.info};
		position: absolute;
		top: 6px;
		left: 6px;
		border-radius: 100%;
		-webkit-transition: all 0.2s ease;
		transition: all 0.2s ease;
	}
	&:not(:checked) + label:after {
		opacity: 0;
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	&:checked + label:after {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
`;

export const Ebanx = (props: {
	active: boolean
}) => {
	const {active} = props;
	const [paymentInfo, setPaymentInfo] = useState<PaymentInfo | undefined | null>();
	const [loading, setLoading] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [apiError, setApiError] = useState(null);
	const [method, setMethod] = useState();
  const [deviceId, setDeviceId] = useState<string>();

	const { country, customer_id, redirect_url, plan_code, otp, subscription_id, addon_code } = useContext(AppContext);
	const { couponCode: coupon_code, compute, instalmentsCount } = useContext(SummaryContext);
	const { getPaymentInfo } = useContext(PaymentContext);
	const history = useHistory();
	const intl = useIntl();
	const withInstalments = !!(compute && compute.payment_instalments);

	useEffect(() => {
		const newWindowObject = window as any;
		const ebanxApi = newWindowObject.EBANX;

		ebanxApi.init({
			publicIntegrationKey: process.env.REACT_APP_PUBLIC_INTEGRATION_KEY || "test_pk_Cy-W6eXvV8BG1vd_XZfbPw",
			country: country?.toLowerCase(),
			mode: process.env.REACT_APP_DEPLOYMENT_ENV === "production" ? "production" : "test",
		});

		ebanxApi.deviceFingerprint
			.getSession()
			.then((session: { device_id: string }) => {
				if (session?.device_id) setDeviceId(session.device_id);
			})

	},[country])

	useEffect(() => {
		if (active && !loaded && customer_id) {
			setLoading(true);

			getPaymentInfo(PAYMENT_METHODS.EBANX)
				.then(payment_info => {
					setPaymentInfo(payment_info);
				})
				.catch(e => {
					// FIXME
					console.error(e.message);
				})
				.finally(() => {
					setLoading(false);
					setLoaded(true);
				})
		}
	}, [customer_id, active]);

	const onMethodSelect = useCallback(
		e => {
			if (!submitting) {
				setApiError(null);
				setMethod(e.target.value);
			}
		},
		[submitting]
	);

	const onCardAdded = useCallback(async () => {
		const payload = {
			customer_id, plan_code, coupon_code, device_id: deviceId, otp, subscription_id, addon_code,
			...(withInstalments && { instalments: instalmentsCount })
		};
		if (method !== NEW_PAYMENT_METHOD && paymentInfo !== null) {
			setApiError(null);
			setSubmitting(true);
		}
		try {
			await subscription.post("/v1/ebanx/charge/", omitBy(payload, isEmptyValue));
			setSubmitting(false);
			dataLayerPush({
				event: 'checkoutEvents',
				category: 'Checkout',
				action: 'Payment successful',
				label: customer_id as string,
			});
			if (redirect_url) {
				window.top.location.href = redirect_url;
			} else {
				history.push({pathname: `${history.location.pathname}/thank-you`, search: history.location.search})
			}
		} catch (err) {
			setSubmitting(false);

			const errData = err.response.data;
			// @ts-ignore
			const errMessage = !!errors[errData.status_code] ? errData.status_code : errData.status_message;
			// @ts-ignore
			const message = errors[errMessage];
			setApiError(message ? intl.formatMessage(message) : message);



			if (method === NEW_PAYMENT_METHOD && paymentInfo !== null) {
				try {
					// we will try to put the original data back so they can use the old card
					await subscription.put("/v1/ebanx/card/", paymentInfo);
					setSubmitting(false);
				} catch (err2) {
					throw err2;
				}
			}

			throw err;
		}
	}, [plan_code, customer_id, redirect_url, method, deviceId, coupon_code, instalmentsCount, withInstalments]);

	const email = paymentInfo ? paymentInfo.email : "";
	const onGetToken = useCallback(
		async (data: any) => {
			const {
				card: { token, payment_type_code, masked_card_number, expiration_date },
				values: { card_name, ...rest },
			} = data;

			const payLoad = {
				customer_id,
				email,
				country,
				token,
				payment_type_code,
				card_mask: masked_card_number,
				name: card_name,
				expiration_date,
				...rest,
			};
			try {
				await subscription.put("/v1/ebanx/card/", payLoad);
				await onCardAdded();
			} catch (err) {
				console.log("onGetToken handler:", err.message);
				throw err;
			}
		},
		[customer_id, country, onCardAdded, email]
	);

	if (loading) {
		return null;
	}

	// @ts-ignore
	const supportedCars = cards[country];

	if (!loaded || loading) return  null;

	if (paymentInfo === null) {

		return (
			<StyledWrapper active={props.active}>
				<SupportedCards cards={supportedCars} />
				<EbanxCreditCardForm initialValues={{country: country!}} onGetTokenSuccess={onGetToken} />
			</StyledWrapper>
		);
	}

	const { name, document, address, street_number, city, state, zipcode, phone_number } = paymentInfo!;

	const initialValues = {
		document,
		address,
		street_number,
		city,
		state,
		zipcode,
		phone_number,
		card_name: name,
		country: country!,
	};

	return (
		<StyledWrapper active={props.active}>
			<SupportedCards cards={supportedCars} />
			<StyledMethodContainer>
				<StyledMethodRow>
					<StyledRadio
						type="radio"
						name="method"
						id={CURRENT_PAYMENT_METHOD}
						value={CURRENT_PAYMENT_METHOD}
						onChange={onMethodSelect}
						defaultChecked
					/>
					<StyledMethodLabel htmlFor={CURRENT_PAYMENT_METHOD}>
						<FormattedMessage {...messages.useExistingCard} values={{ cardMask: paymentInfo!.card_mask }} />
					</StyledMethodLabel>
				</StyledMethodRow>

				<StyledMethodRow>
					<StyledRadio type="radio" name="method" id={NEW_PAYMENT_METHOD} value={NEW_PAYMENT_METHOD} onChange={onMethodSelect} />
					<StyledMethodLabel htmlFor={NEW_PAYMENT_METHOD}>
						<FormattedMessage {...messages.useAnotherCard} />
					</StyledMethodLabel>
				</StyledMethodRow>
			</StyledMethodContainer>
			{method === NEW_PAYMENT_METHOD ? (
				<EbanxCreditCardForm initialValues={initialValues} onGetTokenSuccess={onGetToken} />
			) : (
				<React.Fragment>
					<InstalmentSelect />
					{apiError && <ErrorMessage>{apiError}</ErrorMessage>}
					<Button
						loading={submitting}
						onClick={onCardAdded}
						analyticsEvent={{event: 'checkoutEvents', category: 'Checkout', action: 'Pay', label: 'creditcard'}}
						fullwidth
					>
						{!submitting && <FormattedMessage {...messages.pay} />}
					</Button>
					<TermsAndConditions button={<FormattedMessage {...messages.pay} />}/>
				</React.Fragment>
			)}
		</StyledWrapper>
	);
};
