import { defineMessages } from "react-intl";

export const scope = 'app.charge.payments';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'Payment',
	},
	"tabs.ebanx": {
		id: `${scope}.tabs.ebanx`,
		defaultMessage: 'Card',
	},
	"tabs.boleto": {
		id: `${scope}.tabs.boleto`,
		defaultMessage: 'Boleto',
	},
	"tabs.onepay": {
		id: `${scope}.tabs.onepay`,
		defaultMessage: 'Card',
	},
	"tabs.oxxo": {
		id: `${scope}.tabs.oxxo`,
		defaultMessage: 'Oxxo',
	},
});
