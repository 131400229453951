import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import {FormattedMessage} from "react-intl";

import subscription from "services/subscription";
import { AppContext } from "contexts/AppContext/AppContext";
import Loading from "./Loading"
import messages from "./messages";

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1 0 auto;
	line-height: 1.2;
	flex-direction: column;
`

const Title = styled.h1`
	font-size: 2rem;
	font-weight: 800;
	text-align: center;
`

const Message = styled.span`
	font-weight: 500px;
	font-size: 1rem;
`

export default () => {
	const {redirect_url, query} = useContext(AppContext);
  const history = useHistory();

	useEffect(() => {
		const subscribe = async () => {
				try {
					if (query.has('redirect_url')) {
						query.delete('redirect_url')
					}
					const res = await subscription.get(`/v1/onepay/payment_response?${query.toString()}`)
					if(res && res.status === 200 && redirect_url !== null) {
						window.top.location.href = redirect_url
					}
				} catch (err) {
					history.push({pathname: `./failure`})
				}
			}
		subscribe()
	}, [redirect_url, query, history])


	return (
		<Container>
			<Loading/>
			<Title>
				<FormattedMessage {...messages.title}/>
			</Title>
			<Message>
				<FormattedMessage {...messages.text}/>
			</Message>
		</Container>
	)
}
