import React, { useContext } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import LoaderCircle from "components/LoaderCircle";
import { PaymentContext } from "../../../../contexts/PaymentContext/PaymentContext";
import messages from "./messages";
import { PAYMENT_METHODS } from "../../../../providers/PaymentProvider/constants";
import SectionTitle from "../../../../components/SectionTitle";
import oxxoTabLogo from '../../../../assets/oxxoTabLogo.svg';
import { Ebanx } from "./components/Ebanx";
import { OnePay } from "./components/OnePay";
import { Boleto } from "./components/Boleto";
import { Oxxo } from "./components/Oxxo";

const views = {
	[PAYMENT_METHODS.EBANX]: Ebanx,
	[PAYMENT_METHODS.ONE_PAY]: OnePay,
	[PAYMENT_METHODS.BOLETO]: Boleto,
	[PAYMENT_METHODS.OXXO]: Oxxo,
}

const Tabs = styled.div`
	display: flex;
`;

const Tab = styled.div<{
	active: boolean
}>`
	display: flex;
	flex: 1;
	cursor: pointer;
	font-weight: ${({active}) => active ? 500 : 400};
	color: ${({active, theme}) => active ? theme.colors.text : "gray"};
	padding: 0.5rem;
	font-size: 0.8rem;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid ${({active, theme}) => active ?  theme.colors.info: theme.colors.border};;
`;

const TabIcon = styled.img`
	width: 40px;
	height: 20px;
`;

export const Payments = () => {
	const {loading, providers, activeProvider, setActiveProvider} = useContext(PaymentContext);

	return loading ? (
		<LoaderCircle size={20}/>
	) : (
		<div>
			<SectionTitle>
				<FormattedMessage {...messages.title} />
			</SectionTitle>
			{
				providers.length > 1 ? (
					<Tabs>
						{
							providers.map(provider => {
								// @ts-ignore
								const formattedMessageProps = messages[`tabs.${provider}`];

								return (
									<Tab
										active={provider === activeProvider}
										onClick={() => setActiveProvider(provider)}
										key={provider}
									>
										{provider === PAYMENT_METHODS.OXXO
											? <TabIcon src={oxxoTabLogo} />
											: <FormattedMessage {...formattedMessageProps}/>
										}
									</Tab>
								)
							})
						}
					</Tabs>
				) : null
			}
			<div>
				{providers.map(provider => {
					const PaymentComponent = views[provider];
					return (
						<PaymentComponent active={activeProvider === provider} key={provider} />
					)
				})}
			</div>
		</div>
	)
};
