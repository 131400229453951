import React, { useCallback } from 'react';
import styled from 'styled-components';
import {lighten, desaturate} from "polished";
import LoaderCircle from '../LoaderCircle';
import { dataLayerPush } from '../../services/dataLayer';

const StyledButton = styled.button<{fullwidth: boolean}>`
    background: ${({theme}) => theme.colors.primary};
    border-radius: 1.5rem;
    border-width: 0;
    color: #FFFFFF;
    font-size: 1rem;
    width: ${({fullwidth}) => fullwidth ? "100%" : "auto"};
    padding: 0 0.7em;
    cursor: pointer;
    transition: 0.5s;
    outline: none;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &.disabled,
    &:disabled {
        background-color: ${({theme}) => lighten(0.2294, desaturate(0.0725, theme.colors.primary))};
        cursor: not-allowed;
    }
`;

interface Props {
	fullwidth: boolean
	loading?: boolean
	analyticsEvent?: AnalyticsEvent
}

const Button:React.FC<React.HTMLProps<HTMLButtonElement> & Props> = props => {
	const {loading = false, disabled, onClick, fullwidth, children, analyticsEvent, className} = props;

	const clickHandler = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {

		if(analyticsEvent) {
			dataLayerPush(analyticsEvent);
		}

		if(onClick) {
			e.preventDefault();
			e.stopPropagation();
			onClick(e);
		}
	}, [analyticsEvent, onClick])

	return (
		<StyledButton
			className={className}
			onClick={clickHandler}
			fullwidth={fullwidth}
			disabled={disabled || loading}
		>
			{!loading && children}
			{loading && <LoaderCircle size={20}/>}
		</StyledButton>
	)
}

export default Button;
