import React, {useCallback, useContext, useState} from "react";
import styled from "styled-components";
import {FormattedMessage} from "react-intl";
import { omitBy } from "lodash";
import Button from "components/Button";
import ErrorMessage from "components/ErrorMessage";
import TermsAndConditions from 'components/TermsAndConditions';
import subscription from "services/subscription";
import { isEmptyValue } from "../../../../../../utils/isEmptyValue";

import messages from "./messages";
import {AppContext, SummaryContext} from "../../../../../../contexts";
import { Grid } from "../../../../../../components/Grid/Grid";
import dateIcon from "./assets/date.svg"
import subscriptionIcon from "./assets/subscription.svg"
import { AxiosResponse } from "axios";

const StyledDiv = styled.div<{active: boolean}>`
	padding: 32px 0;
	display: ${({active}) => active ? "block" : "none"};
`;
const StyledIcon = styled.img`
	width: 44px;
	height: 44px;
`;
const StyledText = styled.p`
	color: #333333;
	font-size: 14px;
`;
const StyledErrorWrapper = styled.div`
	margin-top: 32px;
`;
const StyledButton = styled(Button)`
	margin-top: 32px;
`;

interface OxxoPayment {
	amount_br: string
	amount_ext: string
	amount_iof: string
	capture_available: null | string
	confirm_date: null | string
	country: string
	currency_ext: string
	currency_rate: string
	due_date: string
	hash: string
	instalments: string
	merchant_payment_code: string
	open_date: string
	order_number: null | string
	oxxo_barcode: string
	oxxo_url: string
	payment_type_code: string
	pin: string
	pre_approved: boolean
	status: string
	status_date: null | string
	transfer_date: null | string
}

interface OxxoResponse {
	payment: OxxoPayment | null
	status: string
}

interface Props {
	active: boolean
}

const oxxoRedirect = (payment: OxxoPayment | null, redirect_url: string | null) => {
	if(payment) {
		window.open(payment.oxxo_url, "_blank");
	} else if(redirect_url) {
		window.top.location.href = redirect_url;
	} else {
		console.error('no payment returned and redirect_url missing')
	}
}

const analyticsEvent = {
	event: 'checkoutEvents',
	category: 'Checkout',
	action: 'Pay',
	label: 'Oxxo'
};

export const Oxxo = ({ active }: Props) => {
	const { customer_id, plan_code, redirect_url, subscription_id, addon_code } = useContext(AppContext);
	const { couponCode: coupon_code, compute } = useContext(SummaryContext);
	const [loading, setLoading] = useState(false);
	const [apiError, setApiError] = useState(null);
	const [apiResponse, setApiResponse] = useState<OxxoPayment | null>(null);
	const total = compute && compute.total;

	const handlePay = useCallback(() => {
		if (apiResponse === null) {
			setLoading(true);
			const payload = { customer_id, plan_code, coupon_code, subscription_id, addon_code }
			subscription.post("/v1/oxxo/", omitBy(payload, isEmptyValue))
				.then((response: AxiosResponse<OxxoResponse>) => {
					if (response.status === 200) {
						const { payment } = response.data;
						setApiResponse(payment);
						setApiError(null);
						oxxoRedirect(payment, redirect_url)
					}
				})
				.catch((e) => {
					setApiError(e.message)
				})
				.finally(() => {
					setLoading(false);
				})
		} else {
			oxxoRedirect(apiResponse, redirect_url)
		}
	}, [customer_id, plan_code, coupon_code, apiResponse, redirect_url]);

	return (
		<StyledDiv active={active}>
			<Grid direction="column" spacingY={8}>
				<Grid alignItems="center" spacingX={5}>
					<StyledIcon src={dateIcon}/>
					<StyledText><FormattedMessage {...messages.payDatesInfo}/></StyledText>
				</Grid>
				<Grid alignItems="center" spacingX={5}>
					<StyledIcon src={subscriptionIcon}/>
					<StyledText><FormattedMessage {...messages.paySubscriptionInfo}/></StyledText>
				</Grid>
			</Grid>
			{apiError && <StyledErrorWrapper><ErrorMessage>{apiError}</ErrorMessage></StyledErrorWrapper>}
			<StyledButton
				fullwidth
				loading={loading}
				onClick={handlePay}
				analyticsEvent={analyticsEvent}
			>
				{
					total === 0
						? <FormattedMessage {...messages.upgrade} />
						: (
							apiResponse === null
							? <FormattedMessage {...messages.pay} />
							: <FormattedMessage {...messages.show} />
						)
				}
			</StyledButton>
			<TermsAndConditions {...messages.termsAndConditions}/>
		</StyledDiv>
	);
};
