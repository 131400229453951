import { css } from 'styled-components'


export default css`
    html,
    body{
        font-family: ${({theme}) => theme.fonts.regular};
        background: ${({theme}) => theme.colors.background};
        margin: 0;
        padding: 0;
        height: 100%;
    }
    
    * {
				box-sizing: border-box;
    }
    
    #root{
        min-height:100%;
        position:relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    h1 {
        font-family: ${({theme}) => theme.fonts.heading};
    }
    
    h2,h3,h4,h5,h6 {
        font-family: ${({theme}) => theme.fonts.subheading};
    }
    
    .uppercase{
        text-transform: uppercase;
    }
`
