import React, {useCallback, useContext, useState} from "react";
import styled from "styled-components";
import { omitBy } from "lodash";

import printIcon from "./assets/Print.svg"
import barcodeIcon from "./assets/Barcode.svg"
import calendarIcon from "./assets/Calendar.svg"
import checkIcon from "./assets/Check.svg"
import boletoIcon from "./assets/Boleto.svg"

import {Grid} from "../../../../../../../../components/Grid/Grid";

import messages from "./messages";
import boletoMessages from "./../../messages";
import {FormattedMessage} from "react-intl";
import Button from "../../../../../../../../components/Button";
import { AppContext, SummaryContext } from "../../../../../../../../contexts";
import subscription from "../../../../../../../../services/subscription";
import TermsAndConditions from "../../../../../../../../components/TermsAndConditions";
import {AxiosResponse} from "axios";
import ErrorMessage from "../../../../../../../../components/ErrorMessage";
import { isEmptyValue } from "../../../../../../../../utils/isEmptyValue";

const StyledIcon = styled.img`
	width: 44px;
	height: 44px;
`;

const StyledText = styled.p`
	color: #333333;
	font-size: 14px;
`;

const StyledButton = styled(Button)`
	margin-top: 32px;
`;

const StyledBoletoTerms = styled.div`
	margin-top: 16px;
	font-size: 12px;
	text-align: center;
`;

const StyledBoletoPago = styled.div`
	margin-top: 16px;
	font-size: 12px;
	text-align: center;
`;

const StyledBoletoLogo = styled.img`
	margin-top: 16px;
	height: 40px;
`;

interface BoletoResponse {
	address_city: string
	address_state: string
	address_street: string
	address_zip: string
	amount_brl: string
	customer_email: string
	customer_name: string
	customer_phone: string
	customer_taxid: string
	expiration_date: string
	order: string
	order_status: string
	payment_method: string
	product_name: string
	signature: string
	submission_date: string
	url_boleto: string

}

export const Success = React.memo((props) => {
	const {customer_id, plan_code, subscription_id, addon_code} = useContext(AppContext);
	const {couponCode: coupon_code} = useContext(SummaryContext);
	const [loading, setLoading] = useState(false);
	const [apiError, setApiError] = useState(null);
	const [apiResponse, setApiResponse] = useState<BoletoResponse | null>(null);

	const onPayWithBoleto = useCallback(() => {
		if (apiResponse === null) {
			setLoading(true);
			const payload = { customer_id, plan_code, coupon_code, subscription_id, addon_code };
			subscription.post("/v1/boleto/", omitBy(payload, isEmptyValue))
				.then((response: AxiosResponse<BoletoResponse>) => {
					if (response.status === 200) {
						setApiResponse(response.data);
						window.open(response.data.url_boleto, "_blank");
					}
				})
				.catch((e) => {
					setApiError(e.message)
				})
				.finally(() => {
					setLoading(false);
				})
		} else {
			window.open(apiResponse.url_boleto, "_blank");
		}

	}, [apiResponse]);

	return (
		<>
			<Grid direction="column" spacingY={8}>
				<Grid alignItems="center" spacingX={5}>
					<StyledIcon src={printIcon}/>
					<StyledText><FormattedMessage {...messages.print}/></StyledText>
				</Grid>
				<Grid alignItems="center" spacingX={5}>
					<StyledIcon src={barcodeIcon}/>
					<StyledText><FormattedMessage {...messages.payOnline}/></StyledText>
				</Grid>
				<Grid alignItems="center" spacingX={5}>
					<StyledIcon src={calendarIcon}/>
					<StyledText><FormattedMessage {...messages.validFor}/></StyledText>
				</Grid>
				<Grid alignItems="center" spacingX={5}>
					<StyledIcon src={checkIcon}/>
					<StyledText><FormattedMessage {...messages.onceWeReceivePayment}/></StyledText>
				</Grid>
			</Grid>
			{apiError && <ErrorMessage>{apiError}</ErrorMessage>}
			<StyledButton
				onClick={onPayWithBoleto}
				loading={loading}
				fullwidth
				analyticsEvent={{event: 'checkoutEvents', category: 'Checkout', action: 'Pay', label: 'boleto'}}
			>
				{apiResponse === null
					? <FormattedMessage {...messages.payWithBoleto}/>
					: <FormattedMessage {...messages.show}/>
				}
			</StyledButton>
			<StyledBoletoPago>
				<FormattedMessage {...boletoMessages.pago} values={{
					info: (...chunks: any[]) => <a href="https://boletoflash.com/consumidores" target="_blank" rel="noopener noreferrer">{chunks}</a>
				}}/>
			</StyledBoletoPago>
			<Grid justify="center">
				<StyledBoletoLogo src={boletoIcon}/>
			</Grid>
			<StyledBoletoTerms>
				<TermsAndConditions {...boletoMessages.termsAndConditions} />
			</StyledBoletoTerms>
		</>
	)
});
