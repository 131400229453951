import React, {useCallback, useContext, useEffect, useState} from "react";

import {ErrorContext, PaymentContext, ReadyContext} from "../../contexts";
import {PaymentContextInterface} from "../../contexts/types";
import subscription from "../../services/subscription";
import {get} from "lodash";
import {PAYMENT_METHODS} from "./constants";
import {AppContext} from "../../contexts";
import { dataLayerPush } from "services/dataLayer";

export const PaymentProvider = (props: any) => {
	const [loading, setLoading] = useState(false);
	const [providers, setProviders] = useState([]);
	const [activeProvider, setProvider] = useState();

	const setActiveProvider = useCallback((provider: PAYMENT_METHODS) => {
			dataLayerPush({
				event: 'checkoutEvents',
				category: 'Checkout',
				action: 'Payment type toggle',
				label: provider,
			});
			setProvider(provider);
		},
		[setProvider]
	)

	const {
		customer_id,
		plan_code,
		subscription_id,
	} = useContext(AppContext);

	const [, setReady] = useContext(ReadyContext);
	const [, setError] = useContext(ErrorContext);

	const getPaymentInfo = useCallback((provider: PAYMENT_METHODS) => {
		return new Promise((resolve, reject) => {
			subscription
				.get(`/v1/${provider}/card/${customer_id}`)
				.then(response => {
					if (response.status && response.status === 200) {
						const payment_info = get(response, "data.payment_info", null);
						resolve(payment_info)
					}
				})
				.catch(e => {
					reject(e.message)
				})
		});
	}, [customer_id]);

	const getPaymentMethods = useCallback(() => {
		return new Promise((resolve, reject) => {
			// resolve([PAYMENT_METHODS.EBANX, PAYMENT_METHODS.BOLETO, PAYMENT_METHODS.ONE_PAY]);
			subscription
				.get(`/v2/payment_providers`, {
					params: {
						customer_id,
						plan_code,
						subscription_id,
					}
				})
				.then(response => {
					if (response.status && response.status === 200) {
						const payment_providers = get(response, "data.payment_providers", []);
						resolve(payment_providers);
					} else {
						reject(String(response.status))
					}
				})
				.catch(reject);
		});
	}, [customer_id, plan_code]);

	useEffect(() => {
		if(plan_code) {
			setLoading(true);

			getPaymentMethods()
				.then((providers: any) => {
					setProviders(providers)
					setActiveProvider(get(providers, '[0]'))
				})
				.catch(e => {
					// FIXME
					setError(String(e.message));
				})
				.finally(() => {
					setLoading(false);
					setReady((ready) => ({
						...ready,
						payments: true
					}))
				})
		}
	}, [plan_code]);

	const context = {
		loading,
		providers,
		getPaymentInfo,
		activeProvider,
		setActiveProvider,
	} as PaymentContextInterface;

	return (
		<PaymentContext.Provider value={context}>
			{props.children}
		</PaymentContext.Provider>
	)
};
